import { defineStore } from 'pinia'
import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const usebillingStore = defineStore('billingstore', {
    state: () => {
        return {
            planDetails: [],
            billPlanList: [],
            billPlanOrderList: [],
            billPlanProductCategoriesList: [],
            billPlanProductsList: [],
            billPlanPeriodsList: [],
            billPlanPermissionList: [],
            productDetails: [],
            invoiceTemplateList: [],
            receiptTemplateList: [],
            creditNoteTemplateList: [],
            cafTemplateList:[],
            ordersList:[],
            ordersPermission:[],
            deleteOrderLinePercentage:[],
            replaceOrderLinePercentage:[],
            deletenOrderLinePercentage:[],
            addOrderPercentage:[],
            updateOrderLinePercentage:[],
            invoicesList:[],
            invoicesPermission:[],
            invoicePaidPercent:[],
            proformaInvoicesList:[],
            proformaInvoicesPermission:[],
            invoicesPayList:[],
            invoicesPayPermission:[],
            paymentsList:[],
            paymentsPermission:[],
            creditNotesList:[],
            creditNotesPermission:[],
            voucherList:[],
            autoRenewalUserPercentage:[],
            voucherPermission:[],
            scheduledRenewalsList:[],
            scheduledRenewalsPermission:[],
            gracePeriodList:[],
            gracePeriodPermission:[],
            renewalSummaryList:[],
            payLaterUsersList:[],
            payLaterUsersPermission:[],
            staticIpsBillingList:[],
            staticIpsBillingPermission:[],
            billingConfigList:[],
            billingConfigPermission:[],
            pinPunchViewPermission: [],
            pinPunchViewList: [],
            pinPunchGroupPermission: [],
            pinPunchGroupList: [],
            pinBillingPermission: [],
            pinBillingList: [],
            invoiceDashboard: [],
            franchiseInvoiceTemplateList: [],
            allPinsPermission: [],
            allPinsList: [],
            smsLogsList: [],
            smsLogsPermission: [],
            userVoicePhoneBillingList: [],
            userVoicePhoneBillingPermission: [],
            userStaticIpBillingPermission: [],
            userStaticIpBillingList: [],
            userPlanHistoryList: [],
            userPlanHistoryPermission: [],
            userFupHistoryList: [],
            userFupHistoryPermission: [],
            userFupFilterList: [],
            userFupFilterPermission: [],
            paymentsPermission: [],
            userLogsList: [],
            userLogsPermission: [],
            invoiceDashboard:[],
            paymentDashboard:[],
            completedUserSessionsPermission: [],
            completedUserSessionsList: [],  
            subUsersPermission: [],
            subUsersList: [],  
            sessionsDayWisePermission: [],
            sessionsDayWiseList: [],     
            userRenewalLogsList: [],
            userRenewalLogsPermission: [],  
            paymentLogsList: [],
            paymentLogsPermission: [],    
            userOttBillingList: [],
            userOttBillingPermission: [],  
            userIptvBillingList: [],
            userIptvBillingPermission: [],      
            eventLogsPermission: [],
            eventLogsList: [],    
            emailLogsList: [],
            emailLogsPermission: [],     
            notificationDetails: [],
            ledgerTemplateList: [],
            importGSTInvoiceSampleFilePath: ''                                                                            
        }
    },
    getters: {
        planDetailsDataState: (state) => state.planDetails,
        getBillPlanGetters() {
            return this.planDetailsDataState;
        },
        allProductDetails: (state) => state.productDetails,
        getProductDetailsGetters(){
            return this.allProductDetails
        },
        allInvoiceTemplateList: (state) => state.invoiceTemplateList,
        getInvoiceTemplateListGetter(){
            return this.allInvoiceTemplateList
        },
        allReceiptTemplateList: (state) => state.receiptTemplateList,
        getReceiptTemplateListGetter(){
            return this.allReceiptTemplateList
        },
        allCreditNoteTemplateList: (state) => state.creditNoteTemplateList,
        getCreditNoteTemplateListGetter(){
            return this.allCreditNoteTemplateList
        },
        allCAFTemplateList: (state) => state.cafTemplateList,
        getCAFTemplateListGetter(){
            return this.allCAFTemplateList
        },
        allOrdersList: (state) => state.ordersList,
        getOrdersListGetters(){
            return this.allOrdersList
        },
        allOrdersPermission: (state) => state.ordersPermission,
        getOrdersPermissionGetters(){
            return this.allOrdersPermission
        },
        allsocketDeleteOrderUpdate: (state) => state.deleteOrderLinePercentage,
        socketDeleteOrderUpdate(){
            return this.allsocketDeleteOrderUpdate
        },
        allsocketReplaceOrderUpdate: (state) => state.replaceOrderLinePercentage,
        socketReplaceOrderUpdate(){
            return this.allsocketReplaceOrderUpdate
        },
        allsocketDeleteNOrderUpdate: (state) => state.deletenOrderLinePercentage,
        socketDeleteNOrderUpdate(){
            return this.allsocketDeleteNOrderUpdate
        },
        allsocketaddOrderUpdate: (state) => state.addOrderPercentage,
        socketaddOrderUpdate(){
            return this.allsocketaddOrderUpdate
        },
        allsocketUpdateOrderLineUpdate: (state) => state.updateOrderLinePercentage,
        socketUpdateOrderLineUpdate(){
            return this.allsocketUpdateOrderLineUpdate
        },
        allgetInvoicesList: (state) => state.invoicesList,
        getInvoicesListGetters(){
            return this.allgetInvoicesList
        },
        allInvoicesPermission: (state) => state.invoicesPermission,
        getInvoicesPermissionGetters(){
            return this.allInvoicesPermission
        },
        allsocketPaidInvoiceUpdate: (state) => state.invoicePaidPercent,
        socketPaidInvoiceUpdate(){
            return this.allsocketPaidInvoiceUpdate
        },
        allProformaInvoicesList: (state) => state.proformaInvoicesList,
        getProformaInvoicesListGetters(){
            return this.allProformaInvoicesList
        },
        allProformaInvoicesPermission: (state) => state.proformaInvoicesPermission,
        getProformaInvoicesPermissionGetters(){
            return this.allProformaInvoicesPermission
        },
        allInvoicesAssociatePayList: (state) => state.invoicesPayList,
        getInvoicesAssociatePayListGetters(){
          return this.allInvoicesAssociatePayList
        },
        allInvoiceAssociatePayPermission: (state) => state.invoicesPayPermission,
        getInvoiceAssociatePayPermissionGetters(){
          return this.allInvoiceAssociatePayPermission
        },
        allPaymentsList: (state) => state.paymentsList,
        getPaymentsListGetters(){
          return this.allPaymentsList
        },
        allPaymentsPermission: (state) => state.paymentsPermission,
        getPaymentsPermissionGetters(){
          return this.allPaymentsPermission
        },
        allCreditNotesList: (state) => state.creditNotesList,
        getCreditNotesListGetters(){
          return this.allCreditNotesList
        },
        allCreditNotesPermission: (state) => state.creditNotesPermission,
        getCreditNotesPermissionGetters(){
          return this.allCreditNotesPermission
        },

        allVoucherList: (state) => state.voucherList,
        getVoucherListGetters(){
          return this.allVoucherList
        },
        allsocketChangeAutoRenewalUpdate: (state) => state.autoRenewalUserPercentage,
        socketChangeAutoRenewalUpdate(){
          return this.allsocketChangeAutoRenewalUpdate
        },
        allVoucherPermission: (state) => state.voucherPermission,
        getVoucherPermissionGetters(){
          return this.allVoucherPermission
        },
        allScheduledRenewalsList: (state) => state.scheduledRenewalsList,
        getScheduledRenewalsListGetters(){
          return this.allScheduledRenewalsList
        },
        allScheduledRenewalsPermission: (state) => state.scheduledRenewalsPermission,
        getScheduledRenewalsPermissionGetters(){
          return this.allScheduledRenewalsPermission
        },
        allPlanDetails: (state) => state.planDetails,
        getPlanDetailsGetters(){
          return this.allPlanDetails
        }, 
        allBillPlan: (state) => state.billPlanList,
        getBillPlanGetters(){
          return this.allBillPlan
        }, 
        allPlanTable: (state) => state.billPlanOrderList,
        getPlanTableGetters(){
          return this.allPlanTable
        }, 
        allPlanProductCategories: (state) => state.billPlanProductCategoriesList,
        getPlanProductCategoriesGetters(){
          return this.allPlanProductCategories
        }, 
        allPlanProducts: (state) => state.billPlanProductsList,
        getPlanProductsGetters(){
          return this.allPlanProducts
        }, 
        allPlanPeriods: (state) => state.billPlanPeriodsList,
        getPlanPeriodsGetters(){
          return this.allPlanPeriods
        }, 
        allPlanPermission: (state) => state.billPlanPermissionList,
        getPlanPermissionGetters(){
          return this.allPlanPermission
        },
        allGracePeriodList: (state) => state.gracePeriodList,
        getGracePeriodListGetters(){
          return this.allGracePeriodList
        },
        allGracePeriodPermission: (state) => state.gracePeriodPermission,
        getGracePeriodPermissionGetters(){
          return this.allGracePeriodPermission
        },
        allRenewalSummaryList: (state) => state.renewalSummaryList,
        getRenewalSummaryListGetters(){
          return this.allRenewalSummaryList
        },
        allPayLaterUsersList: (state) => state.payLaterUsersList,
        getPayLaterUsersListGetters(){
          return this.allPayLaterUsersList
        },
        allPayLaterUsersPermission: (state) => state.payLaterUsersPermission,
        getPayLaterUsersPermissionGetters(){
          return this.allPayLaterUsersPermission
        },
        allStaticIpsBillingList: (state) => state.staticIpsBillingList,
        getStaticIpsBillingListGetters(){
          return this.allStaticIpsBillingList
        },
        allStaticIpsBillingPermission: (state) => state.staticIpsBillingPermission,
        getStaticIpsBillingPermissionGetters(){
          return this.allStaticIpsBillingPermission
        },
        allBillingConfigList: (state) => state.billingConfigList,
        getBillingConfigListGetters(){
          return this.allBillingConfigList
        },
        allBillingConfigPermission: (state) => state.billingConfigPermission,
        getBillingConfigPermissionGetters(){
          return this.allBillingConfigPermission
        },
        pinPunchViewListState: (state) => state.pinPunchViewList,
        getPinBunchViewGetters() {
          return this.pinPunchViewListState;
        },
        pinPunchViewPermissionState: (state) => state.pinPunchViewPermission,
        getPinBunchViewPermissionGetters() {
          return this.pinPunchViewPermissionState;
        },
        pinPunchGroupListState: (state) => state.pinPunchGroupList,
        getPinBunchGroupGetters() {
          return this.pinPunchGroupListState;
        },
        pinPunchGroupPermissionState: (state) => state.pinPunchGroupPermission,
        getPinBunchGroupPermissionGetters() {
          return this.pinPunchGroupPermissionState;
        },
        pinBillingListState: (state) => state.pinBillingList,
        getPinBillingListGetters() {
          return this.pinBillingListState;
        },
        pinBillingPermissionState: (state) => state.pinBillingPermission,
        getPinBillingPermissionGetters() {
          return this.pinBillingPermissionState;
        },
        invoiceDashboardState: (state) => state.invoiceDashboard,
        getInvoicesDashboardGetter() {
          return this.invoiceDashboardState;
        },
        franchiseInvoiceTemplateListState: (state) => state.franchiseInvoiceTemplateList,
        getFranchiseInvoiceTemplateListGetter() {
          return this.franchiseInvoiceTemplateListState;
        },
        allPinsListState: (state) => state.allPinsList,
        getAllPinsListGetters() {
          return this.allPinsListState;
        },
        allPinsPermissionState: (state) => state.allPinsPermission,
        getAllPinsPermissionGetters() {
          return this.allPinsPermissionState;
        },
        smsLogsListState: (state) => state.smsLogsList,
        getSmsLogsListGetters() {
          return this.smsLogsListState;
        },
        smsLogsPermissionState: (state) => state.smsLogsPermission,
        getSmsLogsPermissionGetters() {
          return this.smsLogsPermissionState;
        },
        userVoicePhoneBillingListState: (state) => state.userVoicePhoneBillingList,
        geUserVoicePhoneBillingListGetters() {
          return this.userVoicePhoneBillingListState;
        },
        userVoicePhoneBillingPermissionState: (state) => state.userVoicePhoneBillingPermission,
        getUserVoicePhoneBillingPermissionGetters() {
          return this.userVoicePhoneBillingPermissionState;
        },
        userStaticIpBillingListState: (state) => state.userStaticIpBillingList,
        geUserStaticIpBillingListGetters() {
          return this.userStaticIpBillingListState;
        },
        userStaticIpBillingPermissionState: (state) => state.userStaticIpBillingPermission,
        getUserStaticIpBillingPermissionGetters() {
          return this.userStaticIpBillingPermissionState;
        },
        userFupFilterListState: (state) => state.userFupFilterList,
        getFupFiltersListGetters() {
          return this.userFupFilterListState;
        },
        userFupFilterPermissionState: (state) => state.userFupFilterPermission,
        getFupFiltersPermissionGetters() {
          return this.userFupFilterPermissionState;
        },
        userLogsListState: (state) => state.userLogsList,
        getUserLogsListGetters() {
          return this.userLogsListState;
        },
        userLogsPermissionState: (state) => state.userLogsPermission,
        getUserLogsPermissionGetters() {
          return this.userLogsPermissionState;
        },
        completedUserSessionsListState: (state) => state.completedUserSessionsList,
        getCompletedUserSessionsGetters() {
          return this.completedUserSessionsListState;
        },
        completedUserSessionsPermissionState: (state) => state.completedUserSessionsPermission,
        getCompletedUserSessionsPermissionGetters() {
          return this.completedUserSessionsPermissionState;
        },
        allgetInvoicesDashboard: (state) => state.invoiceDashboard,
        getInvoicesDashboardGetter(){
          return this.allgetInvoicesDashboard
        },
        allgetPaymentDashboard: (state) => state.paymentDashboard,
        getPaymentDashboardGetter(){
          return this.allgetPaymentDashboard
        },
        subUsersPermissionState: (state) => state.subUsersPermission,
        getSubUsersPermissionGetters() {
          return this.subUsersPermissionState;
        },
        subUsersListState: (state) => state.subUsersList,
        getSubUsersListGetters() {
          return this.subUsersListState;
        },
        sessionsDayWiseListState: (state) => state.sessionsDayWiseList,
        getUserTrafficGetters() {
          return this.sessionsDayWiseListState;
        },
        sessionsDayWisePermissionState: (state) => state.sessionsDayWisePermission,
        getUserTrafficPermissionGetters() {
          return this.sessionsDayWisePermissionState;
        },
        userRenewalLogsListState: (state) => state.userRenewalLogsList,
        getRenewalLogsListGetters() {
          return this.userRenewalLogsListState;
        },
        userRenewalLogsPermissionState: (state) => state.userRenewalLogsPermission,
        getRenewalLogsPermissionGetters() {
          return this.userRenewalLogsPermissionState;
        },
        paymentLogsListState: (state) => state.paymentLogsList,
        getPaymentLogsListGetters() {
          return this.paymentLogsListState;
        },
        paymentLogsPermissionState: (state) => state.paymentLogsPermission,
        getPaymentLogsPermissionGetters() {
          return this.paymentLogsPermissionState;
        },
        userOttBillingListState: (state) => state.userOttBillingList,
        geUserOttBillingListGetters() {
          return this.userOttBillingListState;
        },
        userOttBillingPermissionState: (state) => state.userOttBillingPermission,
        getUserOttBillingPermissionGetters() {
          return this.userOttBillingPermissionState;
        },
        userIptvBillingListState: (state) => state.userIptvBillingList,
        geUserIptvBillingListGetters() {
          return this.userIptvBillingListState;
        },
        userIptvBillingPermissionState: (state) => state.userIptvBillingPermission,
        getUserIptvBillingPermissionGetters() {
          return this.userIptvBillingPermissionState;
        },
        eventLogsListState: (state) => state.eventLogsList,
        getEventLogsListGetters() {
          return this.eventLogsListState;
        },
        eventLogsPermissionState: (state) => state.eventLogsPermission,
        getEventLogsPermissionGetters() {
          return this.eventLogsPermissionState;
        },
        emailLogsListState: (state) => state.emailLogsList,
        getEmailLogsListGetters() {
          return this.emailLogsListState;
        },
        emailLogsPermissionState: (state) => state.emailLogsPermission,
        getEmailLogsPermissionGetters() {
          return this.emailLogsPermissionState;
        },
        notificationDetailsState: (state) => state.notificationDetails,
        getNotificationDetailsGetter() {
          return this.notificationDetailsState;
        },
        ledgerTemplateData: (state) => state.ledgerTemplateList,
        getLedgerTemplateListGetter() {
            return this.ledgerTemplateData
        },
        importGSTInvoiceSampleFilePathState: (state) => state.importGSTInvoiceSampleFilePath,
        getImportGSTInvoiceSampleFilePath() {
          return this.importGSTInvoiceSampleFilePathState;
        },
    },
    actions: {
        fetchPlanDetails() {
          Nprogress.start()
            return new Promise((resolve, reject) => {
            axios.get('/billing/plans/true')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                    resolve(res.data);
                } else {
                    var planDetails = res.data
                    var tData = {};
                    var tData1 = {};
                    var tData2 = {};
                    var tData3 = {};
                    var tData4 = {};
                    var tData5 = {};
                    var tData6 = {};
                    var parseData = planDetails.message;

                    if (Object.keys(this.planDetails).length == 0) {
                        tData[planDetails.accountId + '-planList'] = parseData;
                        this.planDetails = tData;
                        tData1[planDetails.accountId + '-planList'] = parseData.billPlans;
                        this.billPlanList = tData1;
                        tData2[planDetails.accountId + '-planList'] = parseData.tableData;
                        this.billPlanOrderList = tData2;
                        tData3[planDetails.accountId + '-planList'] = parseData.productCategories;
                        this.billPlanProductCategoriesList = tData3;
                        tData4[planDetails.accountId + '-planList'] = parseData.products;
                        this.billPlanProductsList = tData4;
                        tData5[planDetails.accountId + '-planList'] = parseData.periods;
                        this.billPlanPeriodsList = tData5;
                        tData6[planDetails.accountId + '-planList'] = parseData.permissions;
                        this.billPlanPermissionList = tData6;
                    } else {
                      this.planDetails[planDetails.accountId + '-planList']=parseData;
                      this.billPlanList[planDetails.accountId + '-planList']=parseData.billPlans;
                      this.billPlanOrderList[planDetails.accountId + '-planList']=parseData.tableData;
                      this.billPlanProductCategoriesList[planDetails.accountId + '-planList']=parseData.productCategories;
                      this.billPlanProductsList[planDetails.accountId + '-planList']=parseData.products;
                      this.billPlanPeriodsList[planDetails.accountId + '-planList']=parseData.periods;
                      this.billPlanPermissionList[planDetails.accountId + '-planList']=parseData.permissions;
                    }
                }        
              })
              .catch(error => console.log(error))
            });
        },
        fetchProductDetails() {
            Nprogress.start()
            axios.get('/billing/products/true')
                .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                    router.push("/dashboard");
                } else {
                    var tData = {};
                    var parseData = res.data.message;
                    if (Object.keys(this.productDetails).length == 0) {
                    tData[res.data.accountId + '-productList'] = parseData;
                    this.productDetails = tData;
                    } else {
                    Vue.set(this.productDetails, res.data.accountId + '-productList', parseData);
                    }                  
                }
                })
                .catch(error => console.log(error))
        },
        createProductModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/billing/create_product_category', qs.stringify(postData, {
                parseArrays: false
                }))
                .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                    }
                    var productData = JSON.parse(response.data.productCategoryId);
                    var accountId = postData.accountId;
                    if (productData.length > 0) {
                        for (var i = 0; i < productData.length; i++) {
                            var index = this.productDetails[accountId + '-productList'].productCategories.findIndex(productCat => productCat.ItemType.id == productData[i].ItemType.id);
                            if (index != '-1') {
                            this.productDetails[accountId + '-productList'].productCategories.splice(index, 1)
                            }
                            this.productDetails[accountId + '-productList'].productCategories.push(productData[i]);
                        }
                    }
                    resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        pushProductToAllSubZones(postData) {
        return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/push_all_products', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
        deleteProductModalSubmit(postData) {
        return new Promise((resolve, reject) => {
    
          JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_product', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.genericBody);
                return;
                }
                var successData = {
                productIds: response.data.productIds,
                accountId: postData.accountId
                }
                //commit('DELETE_PRODUCT_LIST', successData);
                var productIds = successData.productIds;
                var productCategoryIds = successData.productCategoryIds;
                var accountId = successData.accountId;

                if (Object.keys(this.productDetails).length != 0) {
                for (var i = 0; i < productIds.length; i++) {
                    var index = this.productDetails[accountId + "-productList"].products.findIndex(product => product.id == productIds[i]);
                    this.productDetails[accountId + "-productList"].products.splice(index, 1)
                }
                }

                if (productCategoryIds != undefined && productCategoryIds.length > 0) {
                for (var i = 0; i < productCategoryIds.length; i++) {
                    var index = this.productDetails[accountId + '-productList'].productCategories.findIndex(produtCat => produtCat.ItemType.id == productCategoryIds[i]);
                    if (index != '-1') {
                    this.productDetails[accountId + '-productList'].productCategories.splice(index, 1);
                    }
                }
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
        deleteAllProductModalSubmit(postData) {
        return new Promise((resolve, reject) => {
    
          JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_all_product', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.genericBody);
                return;
                }
                var successData = {
                productIds: response.data.productIds,
                productCategoryIds: response.data.productCategoryIds,
                accountId: postData.accountId
                }
                //commit('DELETE_PRODUCT_LIST', successData);
                var productIds = successData.productIds;
                var productCategoryIds = successData.productCategoryIds;
                var accountId = successData.accountId;
            
                if (Object.keys(this.productDetails).length != 0) {
                for (var i = 0; i < productIds.length; i++) {
                    var index = this.productDetails[accountId + "-productList"].products.findIndex(product => product.id == productIds[i]);
                    this.productDetails[accountId + "-productList"].products.splice(index, 1)
                }
                }
            
                if (productCategoryIds != undefined && productCategoryIds.length > 0) {
                for (var i = 0; i < productCategoryIds.length; i++) {
                    var index = this.productDetails[accountId + '-productList'].productCategories.findIndex(produtCat => produtCat.ItemType.id == productCategoryIds[i]);
                    if (index != '-1') {
                    this.productDetails[accountId + '-productList'].productCategories.splice(index, 1);
                    }
                }
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
        exportProductSubmit(postData) {
        return new Promise((resolve, reject) => {
          JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/export_products_tally', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
        clearProductState() {
        this.productDetails = [];
        },
        addEditProductModalSubmit(postData) {
        return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/create_product', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                var productData = JSON.parse(response.data.productId);
                var accountId = postData.accountId;
                if (!postData.fromProfile) {                    
                if (Object.keys(this.productDetails).length != 0 && this.productDetails != 'noData') {
                    if (this.productDetails != 'noData' && productData.length > 0) {
                    for (var i = 0; i < productData.length; i++) {
                        var index = this.productDetails[accountId + "-productList"].products.findIndex(product => product.id == productData[i].id);
                        if (index != '-1') {
                        this.productDetails[accountId + "-productList"].products.splice(index, 1)
                        }
                        this.productDetails[accountId + "-productList"].products.push(productData[i]);
                    }
                    }
                } else {
                    var dataSet = {}
                    dataSet[accountId + "-productList"].products = productData;
                    this.productDetails = dataSet;
                }                    
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
//InvoiceTemplate    
        clearBillingState() {
          this.ordersList = [];
          this.ordersPermission = [];
          this.invoicesPermission = [];
          this.invoicesPayPermission = [];
          this.scheduledRenewalsPermission = [];
          this.scheduledRenewalsList = [];
          this.invoicesList = [];
          this.invoicesPayList = [];
          this.renewalSummaryList = [];
          this.notificationDetails = [];
          this.invoiceTemplateList = [];
          this.receiptTemplateList = [];
          this.creditNoteTemplateList = [];
          this.cafTemplateList = [];
          this.franchiseInvoiceTemplateList = [];

            //commit('CLEAR_BILLING_STATE')
        },
        fetchInvoiceTemplateList() {
            Nprogress.start()
            axios.get("/billing/invoice_template/true")
                .then(response => {
                Nprogress.done()
                if (response.data.status == "redirect") {
                    router.push("/dashboard");
                } else {
                    //commit('SET_INVOICE_TEMPLATE_DETAILS', response.data)
                    var tData = {};
                    var parseData = response.data.message;
                    if (Object.keys(this.invoiceTemplateList).length == 0) {
                        tData[response.data.accountId + '-invoiceTemplateList'] = parseData;
                        this.invoiceTemplateList = tData;
                    } else {
                        this.invoiceTemplateList[response.data.accountId + '-invoiceTemplateList']= parseData;
                    }
                }
                })
                .catch(error => console.log(error))
        },
        makeDefaultInvoiceTemplateSubmit( authData) {
        return new Promise((resolve, reject) => {
    
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/make_default_template/' + authData.gatewayId)
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        }, 
        saveSubAccountPermissionInvoiceTemplateSettings(postData) {
        return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/save_sub_accounts_cannot_change_templates', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                // commit('SET_TEMPLATE_CHECKBOX_VALUE', postData);
                var accountId = postData.accountId;
                if (postData.type == 'invoice_template') {
                if (postData.switchCheckBoxValue == 'on') {
                    this.invoiceTemplateList[accountId + "-invoiceTemplateList"]['subAccountsCanNotChangeInvoiceTemplate']= 'Yes';
                } else {
                    this.invoiceTemplateList[accountId + "-invoiceTemplateList"]['subAccountsCanNotChangeInvoiceTemplate' ]= 'No';
                }
                } else if (postData.type == 'receipt_template') {
                if (postData.switchCheckBoxValue == 'on') {
                    this.receiptTemplateList[accountId + "-receiptTemplateList"]['subAccountsCanNotChangeReceiptTemplate']='Yes';
                } else {
                    this.receiptTemplateList[accountId + "-receiptTemplateList"]['subAccountsCanNotChangeReceiptTemplate'] ='No';
                }
                } else if (postData.type == 'credit_note_template') {
                if (postData.switchCheckBoxValue == 'on') {
                    this.creditNoteTemplateList[accountId + "-creditNoteTemplateList"]['subAccountsCanNotChangeCreditNoteTemplate']='Yes';
                } else {
                    this.creditNoteTemplateList[accountId + "-creditNoteTemplateList"]['subAccountsCanNotChangeCreditNoteTemplate']= 'No';
                }
                } else if (postData.type == 'caf_template') {
                if (postData.switchCheckBoxValue == 'on') {
                    this.cafTemplateList[accountId + "-cafTemplateList"]['subAccountsCanNotChangeCafTemplate']='Yes';
                } else {
                    this.cafTemplateList[accountId + "-cafTemplateList"]['subAccountsCanNotChangeCafTemplate']='No';
                }
                } else if (postData.type == 'franchise_invoice_template') {
                if (postData.switchCheckBoxValue == 'on') {
                    this.franchiseInvoiceTemplateList[accountId + "-franchiseInvoiceTemplateList"]['subAccountsCanNotChangeFranchiseInvoiceTemplate']='Yes';
                } else {
                    this.franchiseInvoiceTemplateList[accountId + "-franchiseInvoiceTemplateList"]['subAccountsCanNotChangeFranchiseInvoiceTemplate']='No';
                }
                }  else if (postData.type == 'ledger_template') {
                if (postData.switchCheckBoxValue == 'on') {
                    this.ledgerTemplateList[accountId + "-ledgerTemplateList"]['subAccountsCanNotChangeLedgerTemplate']='Yes';
                } else {
                    this.ledgerTemplateList[accountId + "-ledgerTemplateList"]['subAccountsCanNotChangeLedgerTemplate']='No';
                }
                }
                resolve(response.data.genericBody);
            })
            .catch(error => console.log(error));
        });
        },
        addInvoiceTemplateToAllSubZones(postData) {
        return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/add_invoicetemplate_subaccounts', qs.stringify(postData, {
            parseArrays: false
            }))
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                resolve(response.data.genericBody);
            })
            .catch(error => console.log(error));
        });
        },
        deleteInvoiceTemplateSubmit(authData) {
        return new Promise((resolve, reject) => {
    
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/delete_invoice_template/' + authData.gatewayId)
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                var successData = {
                invoiceData: response.data.invoiceTemplateName,
                accountId: response.data.accountId
                }
                //commit('DELETE_INVOICE_TEMPLATE_LIST', successData);
                var invoiceTemplateName = successData.invoiceData;
                var accountId = successData.accountId;
                if (Object.keys(this.invoiceTemplateList).length != 0) {
                var index = this.invoiceTemplateList[accountId + "-invoiceTemplateList"].templates.findIndex(invoiceTemplate => invoiceTemplate.invoiceTemplateName == invoiceTemplateName);
                if (index != '-1') {
                    this.invoiceTemplateList[accountId + "-invoiceTemplateList"].templates.splice(index, 1)
                }
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
//ReceiptTemplate
        // clearBillingState() {
        // this.receiptTemplateList = [];
        // //commit('CLEAR_BILLING_STATE')
        // },
        fetchReceiptTemplateList(authData) {
        Nprogress.start()
        axios.get("/billing/receipt_template/true")
            .then(response => {
            Nprogress.done()
            if (response.data.status == "redirect") {
                router.push("/dashboard");
            } else {
                //commit('SET_RECEIPT_TEMPLATE_DETAILS', response.data)
                var tData = {};
                var parseData = response.data.message;
                if (Object.keys(this.receiptTemplateList).length == 0) {
                    tData[response.data.accountId + '-receiptTemplateList'] = parseData;
                    this.receiptTemplateList = tData;
                } else {
                    this.receiptTemplateList[response.data.accountId + '-receiptTemplateList']= parseData;
                }
            }
            })
            .catch(error => console.log(error))
        },
        makeDefaultReceiptTemplateSubmit(authData) {
        return new Promise((resolve, reject) => {
    
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/make_default_receipt_template/' + authData.gatewayId)
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
      
        addReceiptTemplateToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/billing/add_receipttemplate_subaccounts', qs.stringify(postData, {
                parseArrays: false
                }))
                .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                    }
                    resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
            });
        },
        deleteReceiptTemplateSubmit(authData) {
        return new Promise((resolve, reject) => {
    
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/delete_receipt_template/' + authData.gatewayId)
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                var successData = {
                receiptData: response.data.receiptTemplateName,
                accountId: response.data.accountId
                }
                //commit('DELETE_RECEIPT_TEMPLATE_LIST', successData);
                var receiptTemplateName = successData.receiptData;
                var accountId = successData.accountId;
                if (Object.keys(this.receiptTemplateList).length != 0) {
                var index = this.receiptTemplateList[accountId + "-receiptTemplateList"].templates.findIndex(receiptTemplate => receiptTemplate.receiptTemplateName == receiptTemplateName);
                if (index != '-1') {
                    this.receiptTemplateList[accountId + "-receiptTemplateList"].templates.splice(index, 1)
                }
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
//CreditNoteTemplate
        // clearBillingState() {
        //     //commit('CLEAR_BILLING_STATE')
        // },
        fetchCreditNoteTemplateList(authData) {
            Nprogress.start()
            axios.get("/billing/credit_note_template/true")
              .then(response => {
                Nprogress.done()
                if (response.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_CREDIT_NOTE_TEMPLATE_DETAILS', response.data)
                  var tData = {};
                  var parseData = response.data.message;
                  if (Object.keys(this.creditNoteTemplateList).length == 0) {
                    tData[response.data.accountId + '-creditNoteTemplateList'] = parseData;
                    this.creditNoteTemplateList = tData;
                  } else {
                    Vue.set(this.creditNoteTemplateList, response.data.accountId + '-creditNoteTemplateList', parseData);
                  }
                }
              })
              .catch(error => console.log(error))
        },
        makeDefaultCreditNoteTemplateSubmit(authData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.get('/billing/make_default_credit_note_template/' + authData.gatewayId)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        addCreditNoteTemplateToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/add_credit_note_template_subaccounts', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
            });
        },
        deleteCreditNoteTemplateSubmit(authData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.get('/billing/delete_credit_note_template/' + authData.gatewayId)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    creditNoteData: response.data.creditNoteTemplateName,
                    accountId: response.data.accountId
                  }
                  //commit('DELETE_CREDIT_NOTE_TEMPLATE_LIST', successData);
                  var creditNoteTemplateName = successData.creditNoteData;
                  var accountId = successData.accountId;
                  if (Object.keys(this.creditNoteTemplateList).length != 0) {
                    var index = this.creditNoteTemplateList[accountId + "-creditNoteTemplateList"].templates.findIndex(creditNameTemplate => creditNameTemplate.creditNoteTemplateName == creditNoteTemplateName);
                    if (index != '-1') {
                      this.creditNoteTemplateList[accountId + "-creditNoteTemplateList"].templates.splice(index, 1)
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
//CAFTemplate

        // clearBillingState() {
        //    // commit('CLEAR_BILLING_STATE')
        // },
        fetchCAFTemplateList(authData) {
            Nprogress.start()
            axios.get("/billing/caf_template/true")
              .then(response => {
                Nprogress.done()
                if (response.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_CAF_TEMPLATE_DETAILS', response.data)
                  var tData = {};
                  var parseData = response.data.message;
                  if (Object.keys(this.cafTemplateList).length == 0) {
                    tData[response.data.accountId + '-cafTemplateList'] = parseData;
                    this.cafTemplateList = tData;
                  } else {
                    Vue.set(this.cafTemplateList, response.data.accountId + '-cafTemplateList', parseData);
                  }
                }
              })
              .catch(error => console.log(error))
        },
        makeDefaultCAFTemplateSubmit(authData) {
        return new Promise((resolve, reject) => {
    
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/make_default_caf_template/' + authData.gatewayId)
            .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                reject(response.data.message);
                return;
                }
                resolve(response.data);
            })
            .catch(error => console.log(error));
        });
        },
        addCAFTemplateToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/add_caftemplate_subaccounts', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
            });
        },
        deleteCAFTemplateSubmit(authData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.get('/billing/delete_caf_template/' + authData.gatewayId)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    cafData: response.data.cafTemplateName,
                    accountId: response.data.accountId
                  }
                  //commit('DELETE_CAF_TEMPLATE_LIST', successData);
                  var cafTemplateName = successData.cafData;
                  var accountId = successData.accountId;
                  if (Object.keys(this.cafTemplateList).length != 0) {
                    var index = this.cafTemplateList[accountId + "-cafTemplateList"].templates.findIndex(cafNameTemplate => cafNameTemplate.cafTemplateName == cafTemplateName);
                    if (index != '-1') {
                      this.cafTemplateList[accountId + "-cafTemplateList"].templates.splice(index, 1)
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
//Orders.Vue
        //clearBillingState() {
           // commit('CLEAR_BILLING_STATE')
        //},
        // clearUsersState() {

        // }
        //
        deleteOrderSubmit(postData) {
            return new Promise((resolve, reject) => {
              if (!postData.socketData) {                
               JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              }
              axios.post('/billing/delete_order', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  if (!postData.socketData) {
                    JsLoadingOverlay.hide();
                  }
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    orderIds: response.data.orderIds,
                    accountId: postData.listAccountId
                  }
                  //commit('DELETE_ORDER_LIST', successData);
                  var orderIds = successData.orderIds;
                  var accountId = successData.accountId;
              
                  if (Object.keys(this.ordersList).length != 0) {
                    for (var i = 0; i < orderIds.length; i++) {
                      var index = this.ordersList[accountId + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderIds[i]);
                      this.ordersList[accountId + "-ordersList"].splice(index, 1)
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        updateOrderSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/update_order_lines', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        editBillingCycle(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/users/order_billing_cycle_change', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var dataList = {
                    orderData: response.data.orderDetail,
                    type: "Billing Cycle",
                    accountId: postData.accountId
                  }
                  console.log("0", dataList);
                  //commit('UPDATE_ORDER_LIST', dataList)
                  console.log("1", dataList);
                    var orderData = JSON.parse(dataList.orderData);
                    var account = dataList.accountId;
                    var type = dataList.type;
                    if (type == "Generate Invoice From Order") {
                    if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                        if (orderData[0].PurchaseOrder.id != undefined || orderData[0].PurchaseOrder.id != null || orderData[0].PurchaseOrder.id != "") {
                        var index1 = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData[0].PurchaseOrder.id);
                        if (index1 != '-1') {
                            this.ordersList[account + "-ordersList"][index1].PurchaseOrder.status_id = orderData[0].PurchaseOrder.status_id;
                            return;
                        }
                        }
                    }
                    }

                    if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                    if (orderData.id != undefined || orderData.id != null || orderData.id != "") {
                        var index = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData.id);
                        if (index != '-1') {
                        if (type == "Customer Order Number") {
                            this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_number = orderData.customer_order_number;
                        } else if (type == "Customer Order Date") {
                            this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_date = orderData.customer_order_date;
                        } else if (type == "Billing Cycle") {
                            this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_value = orderData.due_date_value;
                            this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_unit_id = orderData.due_date_unit_id;
                        }
                        }
                    }
                    this.ordersList = this.ordersList;
                    }

                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        deleteOrderLine(postData) {
            return new Promise((resolve, reject) => {
              if (!postData.socketValue) {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              }
              axios.post('/users/delete_order_line', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  if (!postData.socketValue) {
                    JsLoadingOverlay.hide();
                  }
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        deleteOrderLineall(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/delete_billing_order_line', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.genericBody);
                    return;
                  }
                  var successData = {
                    orderIds: response.data.orderIds,
                    accountId: postData.accountId
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        addOrderLine(postData) {
            return new Promise((resolve, reject) => {
              if (!postData.socketData) {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              }
              axios.post('/billing/add_order_line', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  if (!postData.socketData) {
                    JsLoadingOverlay.hide();
                  }
                  if (response.data.status != 'success') {
                    reject(response.data.genericBody);
                    return;
                  }
                  var successData = {
                    orderIds: response.data.orderIds,
                    accountId: postData.accountId
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        editOrderLinePrice(postData) {
          return new Promise((resolve, reject) => {
            if (!postData.socketData) {
              JsLoadingOverlay.show({
                  'spinnerIcon': 'ball-clip-rotate',
                  "overlayOpacity": "0.6",
                  "spinnerSize": "2x",
                  "lockScroll": true,
              });
            }
            axios.post('/users/edit_order_line_price', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                if (!postData.socketData) {
                  JsLoadingOverlay.hide();
                }
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
      },
        replaceOrderLine(postData) {
            return new Promise((resolve, reject) => {
              if (!postData.socketData) {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              }
              axios.post('/billing/replace_billing_order_line', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  if (!postData.socketData) {
                    JsLoadingOverlay.hide();
        
                  }
                  if (response.data.status != 'success') {
                    reject(response.data.genericBody);
                    return;
                  }
                  var successData = {
                    orderIds: response.data.orderIds,
                    accountId: postData.accountId
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        customerOrderNumberEdit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/edit_customer_order_number', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var dataList = {
                    orderData: response.data.orderDetail,
                    type: 'Customer Order Number',
                    accountId: postData.name
                  }
                  //commit('UPDATE_ORDER_LIST', dataList)
                  var orderData = JSON.parse(dataList.orderData);
                  var accountId = orderData.accountId;
                  var account = dataList.accountId;
                  var type = dataList.type;
                  console.log("state", state);
                  console.log("orderData", orderData);
                  if (type == "Generate Invoice From Order") {
                    if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                      if (orderData[0].PurchaseOrder.id != undefined || orderData[0].PurchaseOrder.id != null || orderData[0].PurchaseOrder.id != "") {
                        console.log("order", order);
                        var index1 = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData[0].PurchaseOrder.id);
                        console.log("index1", index1);
                        if (index1 != '-1') {
                          this.ordersList[account + "-ordersList"][index1].PurchaseOrder.status_id = orderData[0].PurchaseOrder.status_id;
                          return;
                        }
                      }
                    }
                  }
              
                  if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                    console.log("asdfghjksdfgh");
                    if (orderData.id != undefined || orderData.id != null || orderData.id != "") {
                      //  console.log("order",order.PurchaseOrder.id);
                      console.log("order1", account);
                      console.log("order2", this.ordersList[account + "-ordersList"]);
                      // var temp =JSON.parse(this.ordersList);
                      var index = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData.id);
                      // this.ordersList[account + "-ordersList"] = JSON.stringify(temp);
              
                      console.log("qwerty", this.ordersList[account + "-ordersList"]);
                      if (index != '-1') {
                        if (type == "Customer Order Number") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_number = orderData.customer_order_number;
                        } else if (type == "Customer Order Date") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_date = orderData.customer_order_date;
                        } else if (type == "Billing Cycle") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_value = orderData.due_date_value;
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_unit_id = orderData.due_date_unit_id;
                        }
                      }
                    }
                    this.ordersList = this.ordersList;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        customerOrderDateEdit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/edit_customer_order_date', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var dataList = {
                    orderData: response.data.orderDetail,
                    type: 'Customer Order Date',
                    accountId: postData.name
                  }
                  //commit('UPDATE_ORDER_LIST', dataList)
                  var orderData = JSON.parse(dataList.orderData);
                  var accountId = orderData.accountId;
                  var account = dataList.accountId;
                  var type = dataList.type;
                  console.log("state", state);
                  console.log("orderData", orderData);
                  if (type == "Generate Invoice From Order") {
                    if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                      if (orderData[0].PurchaseOrder.id != undefined || orderData[0].PurchaseOrder.id != null || orderData[0].PurchaseOrder.id != "") {
                        console.log("order", order);
                        var index1 = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData[0].PurchaseOrder.id);
                        console.log("index1", index1);
                        if (index1 != '-1') {
                          this.ordersList[account + "-ordersList"][index1].PurchaseOrder.status_id = orderData[0].PurchaseOrder.status_id;
                          return;
                        }
                      }
                    }
                  }
              
                  if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                    console.log("asdfghjksdfgh");
                    if (orderData.id != undefined || orderData.id != null || orderData.id != "") {
                      //  console.log("order",order.PurchaseOrder.id);
                      console.log("order1", account);
                      console.log("order2", this.ordersList[account + "-ordersList"]);
                      // var temp =JSON.parse(this.ordersList);
                      var index = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData.id);
                      // this.ordersList[account + "-ordersList"] = JSON.stringify(temp);
              
                      console.log("qwerty", this.ordersList[account + "-ordersList"]);
                      if (index != '-1') {
                        if (type == "Customer Order Number") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_number = orderData.customer_order_number;
                        } else if (type == "Customer Order Date") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_date = orderData.customer_order_date;
                        } else if (type == "Billing Cycle") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_value = orderData.due_date_value;
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_unit_id = orderData.due_date_unit_id;
                        }
                      }
                    }
                    this.ordersList = this.ordersList;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        fetchSubZoneDetails(authData) {
            return new Promise((resolve, reject) => {
              axios.get('/billing/get_sub_zone_details/' + authData.accountId)
                .then(res => {
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        fetchOrdersList(authData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/billing/get_all_orders', qs.stringify(authData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  //commit('SET_ORDER_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.ordersPermission = res.data.permissions;
                  this.ordersList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-ordersList'] = parseData;
                    this.ordersList = tData;
                  }
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        fetchOrdersList(authData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/billing/get_all_orders', qs.stringify(authData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  //commit('SET_ORDER_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.ordersPermission = res.data.permissions;
                  this.ordersList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-ordersList'] = parseData;
                    this.ordersList = tData;
                  }
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        createOrderBillPlanSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/create_order_for_all_user', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        addEditInvoiceTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/invoiceTemplateSubmit', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  templates: response.data.templates,
                  accountId: response.data.accountId
                }
                //commit('ADD_EDIT_INVOICE_TEMPLATE_LIST', successData);
                var templates = successData.templates;
                var accountId = successData.accountId;
                this.invoiceTemplateList[accountId + '-invoiceTemplateList']['templates']= templates;
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addEditReceiptTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/receiptTemplateSubmit', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  templates: response.data.templates,
                  accountId: response.data.accountId
                }
                //commit('ADD_EDIT_RECEIPT_TEMPLATE_LIST', successData);
                var templates = successData.templates;
                var accountId = successData.accountId;
                this.receiptTemplateList[accountId + '-receiptTemplateList']['templates']= templates;
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addEditCreditNoteTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/creditNoteTemplateSubmit', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  templates: response.data.templates,
                  accountId: response.data.accountId
                }
                //commit('ADD_EDIT_CREDIT_NOTE_TEMPLATE_LIST', successData);
                var templates = successData.templates;
                var accountId = successData.accountId;
                this.creditNoteTemplateList[accountId + '-creditNoteTemplateList']['templates']= templates;
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addEditCAFTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/cafTemplateSubmit', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  templates: response.data.templates,
                  accountId: response.data.accountId
                }
                //commit('ADD_EDIT_CAF_TEMPLATE_LIST', successData);
                var templates = successData.templates;
                var accountId = successData.accountId;
                this.cafTemplateList[accountId + '-cafTemplateList']['templates']=templates;
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        createOrderModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/create_orders', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                if (postData.actionType != 'add_line') {
                  var dataList = {
                    orderdata: response.data.orderId,
                    accountId: response.data.currentAccountId
                  }
                  //commit('UPDATE_SINGLE_ORDER_LIST', dataList)
                  var orderData = JSON.parse(dataList.orderdata);
                  var accountId = dataList.accountId;
                  var type = dataList.type;
                  if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData' && orderData.length > 0) {
                    var index = this.ordersList[accountId + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData[0].PurchaseOrder.id);
                    if (index != '-1') {
                      this.ordersList[accountId + "-ordersList"].splice(index, 1, orderData[0]);
                    } else {
                      this.ordersList[accountId + "-ordersList"].unshift(orderData[0]);
                    }
                  }
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//Invoices.vue
         //clearBillingState() {
           // commit('CLEAR_BILLING_STATE')
        //},
        // clearUsersState() {

        // }
        //
        //fetchSubZoneDetails()
        fetchInvoicesList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_invoices', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_INVOICE_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.invoicesPermission = res.data.permissions;
                  this.importGSTInvoiceSampleFilePath = res.data.samplePath;
                  this.invoicesList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-invoicesList'] = parseData;
                    this.invoicesList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        createdInvoiceDateEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_create_date', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  invoicePayData: response.data.returnData,
                  type: 'Created Date'
                }
                //commit('UPDATE_INVOICE_LIST', dataList)
                var invoicePayData = dataList.invoicePayData;
                var accountId = invoicePayData.accountId;
                var type = dataList.type;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData') {
                  if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoicePayData.id);
                    if (index != '-1') {
                      if (type == "Change Invoice Numbers") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.public_number = invoicePayData.public_number;
                      } else if (type == "Created Date") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.create_datetime = invoicePayData.create_datetime;
                      } else if (type == "Change Po Numbers") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.po_number = invoicePayData.po_number;
                      }
                    }
                  }
                  this.invoicesPayList = this.invoicesPayList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        publicNumberEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_public_number', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  invoicePayData: response.data.returnData,
                  type: 'Change Invoice Numbers'
                }
                //commit('UPDATE_INVOICE_LIST', dataList)
                var invoicePayData = dataList.invoicePayData;
                var accountId = invoicePayData.accountId;
                var type = dataList.type;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData') {
                  if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoicePayData.id);
                    if (index != '-1') {
                      if (type == "Change Invoice Numbers") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.public_number = invoicePayData.public_number;
                      } else if (type == "Created Date") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.create_datetime = invoicePayData.create_datetime;
                      } else if (type == "Change Po Numbers") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.po_number = invoicePayData.po_number;
                      }
                    }
                  }
                  this.invoicesPayList = this.invoicesPayList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        poNumberEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_po_number', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  invoicePayData: response.data.returnData,
                  type: 'Change Po Numbers'
                }
                //commit('UPDATE_INVOICE_LIST', dataList)
                var invoicePayData = dataList.invoicePayData;
                var accountId = invoicePayData.accountId;
                var type = dataList.type;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData') {
                  if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoicePayData.id);
                    if (index != '-1') {
                      if (type == "Change Invoice Numbers") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.public_number = invoicePayData.public_number;
                      } else if (type == "Created Date") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.create_datetime = invoicePayData.create_datetime;
                      } else if (type == "Change Po Numbers") {
                        this.invoicesList[accountId + "-invoicesList"][index].Invoice.po_number = invoicePayData.po_number;
                      }
                    }
                  }
                  this.invoicesPayList = this.invoicesPayList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        downloadInvoiceSubmit(postData) {
      
          JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
          axios.post('/users/download_invoice', qs.stringify(postData, {
            parseArrays: false
          }))
            .then(response => {
              JsLoadingOverlay.hide();
              if (response.data.status != 'success') {
                return;
              }
              if (postData.mobileView == "mobile") {
                window.open(response.data.mailAttachment);
                return;
              }
              window.open("/backend" + response.data.message);
            })
            .catch(error => console.log(error));
        },
        deleteInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_billing_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceIds: response.data.invoiceIds,
                  accountId: postData.listAccountId
                }
      
                ///commit('DELETE_INVOICES_LIST', successData);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        makeMultiplePaymentInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/make_billing_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: postData.listAccountId
                }
      
                resolve(response.data);
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
              })
              .catch(error => console.log(error));
          });
        },
        sendInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if (postData.sendType == 'mail') {
              axios.post('/billing/send_all_invoices', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            } else {
              axios.post('/billing/send_all_sms_invoices', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            }
          });
        },
        changeInvoiceOrPaymentNumberModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              if(postData.from == "invoices") {
                axios.post('/billing/change_invoice_numbers', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    resolve(response.data);
                  })
                  .catch(error => console.log(error));
              } else if(postData.from == "payments") {
                axios.post('/billing/change_payment_numbers', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    resolve(response.data);
                  })
                  .catch(error => console.log(error));
              }
          });
        },
        downloadInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/download_all_invoices', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var filePath = response.data.message.host + '/' + response.data.message.filePath;
                window.open(filePath);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        regenerateInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
              'spinnerIcon': 'ball-clip-rotate',
              "overlayOpacity": "0.6",
              "spinnerSize": "2x",
              "lockScroll": true,
          });
            axios.post('/billing/regenerate_invoices', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        regenerateCreditNoteModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
              'spinnerIcon': 'ball-clip-rotate',
              "overlayOpacity": "0.6",
              "spinnerSize": "2x",
              "lockScroll": true,
          });
            axios.post('/billing/regenerate_credit_notes', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide()
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        regeneratePaymentModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
              'spinnerIcon': 'ball-clip-rotate',
              "overlayOpacity": "0.6",
              "spinnerSize": "2x",
              "lockScroll": true,
          });
            axios.post('/billing/regenerate_payments', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide()
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        creditNoteInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/credit_note_billing_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status == 'error') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.currentAccountId
                }
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
              })
              .catch(error => console.log(error));
          });
        },
        exportLedgerInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/master_ledger', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                window.open("/backend" + response.data.message);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        exportTallyInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/export_to_tally', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                if(postData.invoiceExportTo=="submitEInvoice" || postData.invoiceExportTo=="submitEInvoiceCancel"){
                  resolve(response.data);
                  return;
                }
                if(postData.invoiceExportTo=="gst"){
                  window.open("/backend" + response.data.message);
                  window.open("/backend" + response.data.itemMessage);
                }else{
                  window.open("/backend" + response.data.message);
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        exportCreditNoteModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
              'spinnerIcon': 'ball-clip-rotate',
              "overlayOpacity": "0.6",
              "spinnerSize": "2x",
              "lockScroll": true,
          });
            axios.post('/billing/export_credit_note_gst', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide()
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                if(postData.creditNoteExportTo=="gst"){
                  window.open("/backend" + response.data.message);
                  window.open("/backend" + response.data.itemMessage);
                }else{
                  window.open("/backend" + response.data.message);
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        iptvInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/iptv/send_iptv_message', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        quickbookInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/quickbook_sync', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        increaseInvoiceLineQuantity(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/increase_or_decrease_invoice_line_quantity', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
      
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteInvoiceLine(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_invoice_line', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getPayProformaInvoicesDetailsSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getInvoiceDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addInvoiceLine(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/add_invoice_line', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
      
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        unlinkPaymentSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/unlink_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
      
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//MakePaymentModal
        payInvoiceSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
              'spinnerIcon': 'ball-clip-rotate',
              "overlayOpacity": "0.2",
              "spinnerSize": "2x",
              "lockScroll": true,
          });
            // axios.post('/users/make_user_payment', qs.stringify(postData, {
            //   parseArrays: false
            // }))
            axios.post('/users/make_user_payment', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
      
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        payInvoiceLeadSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            // axios.post('/lead_management/make_lead_payment', qs.stringify(postData, {
            //    parseArrays: false
            //  }))
            axios.post('/lead_management/make_lead_payment', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//Importgstinvoice
        importGstInvoiceSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/import_gst_invoice_file', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },

//ProformaInvoices
        sendInvoiceEmailSubmit(postData) {
          return new Promise((resolve, reject) => {

            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/mail_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  if (response.data.status == null || response.data.status == "") {
                    reject("Unable to dispatch E-mail.Check for E-mail template disable.");
                  } else {
                    resolve(response.data);
                  }

                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        downloadSingleProformaInvoiceModalSubmit(postData) {

          JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
          axios.post('/users/download_proforma_invoice', qs.stringify(postData, {
            parseArrays: false
          }))
            .then(response => {
              JsLoadingOverlay.hide();
              if (response.data.status != 'success') {
                return;
              }
              window.open("/backend" + response.data.message);
            })
            .catch(error => console.log(error));
        },
        //fetchSubZoneDetails()
        fetchProformaInvoicesList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_proforma_invoices', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_PROFORMA_INVOICE_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.proformaInvoicesPermission = res.data.permissions;
                  this.proformaInvoicesList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-proformaInvoicesList'] = parseData;
                    this.proformaInvoicesList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        deleteProformaInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_billing_proforma_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  resolve(response.data);
                  return;
                }
                var successData = {
                  invoiceIds: response.data.invoiceIds,
                  accountId: postData.accountId
                }
      
                //commit('DELETE_PROFORMA_INVOICES_LIST', successData);
                var invoicesIds = successData.invoiceIds;
                var accountId = successData.accountId;
            
                if (Object.keys(this.proformaInvoicesList).length != 0) {
                  for (var i = 0; i < invoicesIds.length; i++) {
                    var index = this.proformaInvoicesList[accountId + "-proformaInvoicesList"].findIndex(invoice => invoice.Invoice.id == invoicesIds[i]);
                    if (index != "-1") {
                      this.proformaInvoicesList[accountId + "-proformaInvoicesList"].splice(index, 1);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        sendProformaInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/send_all_proforma_invoices', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        downloadProformaInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/download_all_proforma_invoices', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  resolve(response.data);
                  return;
                }
                var filePath = response.data.message.host + '/' + response.data.message.filePath;
                window.open(filePath);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        convertProformaInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/convert_proformato_original_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getPayProformaInvoicesDetailsSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getInvoiceDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getProformaInvoicesDetailsSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getProformaInvoiceDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
      
//InvoiceAssociatedWithPayments
        addInvoiceLine(postData) {
          return new Promise((resolve, reject) => {

            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/add_invoice_line', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }

                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        invoiceNumberEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/change_invoice_numbers', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  invoicePayData: response.data.invoicePayDetail,
                  type: 'Change Invoice Numbers'
                }
                //commit('UPDATE_INVOICEPAY_LIST', dataList)
                var invoicePayData = JSON.parse(dataList.invoicePayData);
                var accountId = invoicePayData.accountId;
                var type = dataList.type;
                if (Object.keys(this.invoicesPayList).length != 0 && this.invoicesPayList != 'noData') {
                  if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                    var index = this.invoicesPayList[accountId + "-invoicesPayList"].findIndex(invoice => invoice.Invoice.id == invoicePayData.id);
                    if (index != '-1') {
                      if (type == "Change Invoice Numbers") {
                        this.invoicesPayList[accountId + "-invoicesPayList"][index].Invoice.public_number = invoicePayData.public_number;
                      } else if (type == "Created Date") {
                        this.invoicesPayList[accountId + "-invoicesPayList"][index].Invoice.create_datetime = invoicePayData.create_datetime;
                      }
                    }
                  }
                  this.invoicesPayList = this.invoicesPayList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        createdDateEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_create_date', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  invoicePayData: response.data.invoicePayDetail,
                  type: 'Created Date'
                }
                //commit('UPDATE_INVOICEPAY_LIST', dataList)
                var invoicePayData = JSON.parse(dataList.invoicePayData);
                var accountId = invoicePayData.accountId;
                var type = dataList.type;
                if (Object.keys(this.invoicesPayList).length != 0 && this.invoicesPayList != 'noData') {
                  if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                    var index = this.invoicesPayList[accountId + "-invoicesPayList"].findIndex(invoice => invoice.Invoice.id == invoicePayData.id);
                    if (index != '-1') {
                      if (type == "Change Invoice Numbers") {
                        this.invoicesPayList[accountId + "-invoicesPayList"][index].Invoice.public_number = invoicePayData.public_number;
                      } else if (type == "Created Date") {
                        this.invoicesPayList[accountId + "-invoicesPayList"][index].Invoice.create_datetime = invoicePayData.create_datetime;
                      }
                    }
                  }
                  this.invoicesPayList = this.invoicesPayList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        //fetchSubZoneDetails
        fetchInvoicesPayList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_invoices_associated_with_payments', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_INVOICE_PAY_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.invoicesPayPermission = res.data.permissions;
                  this.invoicesPayList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-invoicesPayList'] = parseData;
                    this.invoicesPayList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        downloadInvoicePaySubmit(postData) {
          JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
          axios.post('/users/download_invoice', qs.stringify(postData, {
            parseArrays: false
          }))
            .then(response => {
              JsLoadingOverlay.hide();
              if (response.data.status != 'success') {
                return;
              }
              window.open("/backend" + response.data.message);
            })
            .catch(error => console.log(error));
        },
        increaseInvoiceLineQuantity(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/increase_or_decrease_invoice_line_quantity', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
      
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteInvoiceLine(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_invoice_line', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  invoiceData: response.data.invoiceData,
                  accountId: response.data.presentAccountId
                }
                //commit('UPDATE_INVOICES_LIST', successData);
                var invoiceData = JSON.parse(successData.invoiceData);
                var accountId = successData.accountId;
                if (Object.keys(this.invoicesList).length != 0 && this.invoicesList != 'noData' && invoiceData.length > 0) {
                  for (var i = 0; i < invoiceData.length; i++) {
                    var index = this.invoicesList[accountId + "-invoicesList"].findIndex(invoice => invoice.Invoice.id == invoiceData[i].Invoice.id);
                    if (index != '-1') {
                      this.invoicesList[accountId + "-invoicesList"].splice(index, 1, invoiceData[i]);
                    } else {
                      this.invoicesList[accountId + "-invoicesList"].push(invoiceData[i]);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        sendInvoicePayEmailSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/mail_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  if (response.data.status == null || response.data.status == "") {
                    reject("Unable to dispatch E-mail.Check for E-mail template disable.");
                  } else {
                    reject(response.data.genericBody);
                  }
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        //fetchInvoicesPayList
        getInvoiceAssociatedPaymentDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getInvoiceAssociatedPaymentDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//Payments.vue
        clearPaymentState() {
          this.paymentsPermission = [];
          this.paymentsList = [];
          this.invoicesPayList = [];
          //commit('CLEAR_PAYMENT_STATE')
        },
        linkPaymentSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/link_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getPaymentDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getPaymentDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        //fetchSubZoneDetails
        fetchPaymentsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_payments', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_PAYMENT_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.paymentsPermission = res.data.permissions;
                  this.paymentsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-paymentsList'] = parseData;
                    this.paymentsList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        createdPaymentDateEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_payment_created_date', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  invoicePayData: {
                    accountId: postData.accId,
                    create_datetime: postData.value,
                    id: postData.pk
                  },
                }
                //commit('UPDATE_PAYMENT_LIST', dataList)
                var invoicePayData = dataList.invoicePayData;
                var accountId = invoicePayData.accountId;
                if (Object.keys(this.paymentsList).length != 0 && this.paymentsList != 'noData') {
                  if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                    var index = this.paymentsList[accountId + "-paymentsList"].findIndex(invoice => invoice.Payment.id == invoicePayData.id);
                    if (index != '-1') {
                      var datetime = moment(
                        invoicePayData.create_datetime,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD-MM-YYYY HH:mm");
                      this.paymentsList[accountId + "-paymentsList"][index].Payment.create_datetime = datetime;
                    }
                  }
                  this.paymentsList = this.paymentsList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        sendPaymentEmailSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/mail_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        sendPaymentSmsSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/sms_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        downloadPaymentSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/download_receipt', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                window.open("/backend" + response.data.message);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deletePaymentModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_billing_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  paymentIds: response.data.paymentIds,
                  accountId: postData.listAccountId
                }
      
                //commit('DELETE_PAYMENT_LIST', successData);
                var paymentIds = successData.paymentIds;
                var accountId = successData.accountId;
                if (Object.keys(this.paymentsList).length != 0) {
                  for (var i = 0; i < paymentIds.length; i++) {
                    var index = this.paymentsList[accountId + "-paymentsList"].findIndex(payment => payment.Payment.id == paymentIds[i]);
                    this.paymentsList[accountId + "-paymentsList"].splice(index, 1)
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        refundPaymentModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/refund_billing_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  paymentIds: response.data.paymentIds,
                  accountId: postData.listAccountId
                }
      
                //commit('DELETE_PAYMENT_LIST', successData);
                var paymentIds = successData.paymentIds;
                var accountId = successData.accountId;
                if (Object.keys(this.paymentsList).length != 0) {
                  for (var i = 0; i < paymentIds.length; i++) {
                    var index = this.paymentsList[accountId + "-paymentsList"].findIndex(payment => payment.Payment.id == paymentIds[i]);
                    this.paymentsList[accountId + "-paymentsList"].splice(index, 1)
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        exportTallyPaymentModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/export_payment_to_tally', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                window.open("/backend" + response.data.message);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        exportLedgerPaymentModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/master_ledger_payment', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                window.open("/backend" + response.data.message);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//CreditNotes
        downloadCreditNotesModalSubmit(postData) {
          return new Promise((resolve, reject) => {

            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/download_credit_note', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var filePath = "/backend" + response.data.message;
                window.open(filePath);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        //fetchSubZoneDetails
        fetchCreditNotesList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_credit_notes', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_CREDIT_NOTES_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.creditNotesPermission = res.data.permissions;
                  this.creditNotesList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-creditNotesList'] = parseData;
                    this.creditNotesList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        creditNoteNumEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_credit_note_number', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  creNumData: response.data.returnData,
                  type: 'Change CreditNote Numbers'
                }
                  //commit('UPDATE_CREDIT_NOTE_LIST', dataList)
                  var invoicePayData = dataList.creNumData;
                  var accountId = invoicePayData.accountId;
                  var type = dataList.type;
                  if (Object.keys(this.creditNotesList).length != 0 && this.creditNotesList != 'noData') {
                    if (invoicePayData.id != undefined || invoicePayData.id != null || invoicePayData.id != "") {
                      var index = this.creditNotesList[accountId + "-creditNotesList"].findIndex(invoice => invoice.CreditNote.id == invoicePayData.id);
                      if (index != '-1') {
                        if (type == "Change CreditNote Numbers") {
                          this.creditNotesList[accountId + "-creditNotesList"][index].CreditNote.public_number = invoicePayData.public_number;
                        }
                      }
                    }
                    this.creditNotesList = this.creditNotesList;
                  }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteCreditNoteModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_credit_notes', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success' && response.data.isDatabaseAccessibleCheck !='yes') {
                  reject(response.data.message);
                  return;
                } else if(response.data.status=="error" && response.data.isDatabaseAccessibleCheck=="yes"){
                  resolve(response.data);
                  return;
                }
                var successData = {
                  invoiceIds: postData.userIds,
                  accountId: response.data.currentAccountId
                }
                resolve(response.data);
                //commit('DELETE_CREDIT_NOTES_LIST', successData);
                var invoicesIds = successData.invoiceIds;
                var accountId = successData.accountId;

                if (Object.keys(this.creditNotesList).length != 0) {
                  for (var i = 0; i < invoicesIds.length; i++) {
                    var index = this.creditNotesList[accountId + "-creditNotesList"].findIndex(invoice => invoice.CreditNote.id == invoicesIds[i]);
                    this.creditNotesList[accountId + "-creditNotesList"].splice(index, 1)
                  }
                }
              })
              .catch(error => console.log(error));
          });
        },
        viewCreditNotesModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getCreditNoteDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        changeCreditNoteNumberModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/change_credit_note_numbers', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//PayasYougoUsers
        clearVoucherBasedBillingState() {
          //commit('CLEAR_PAY_AS_YOU_GO_USER_STATE')
          this.voucherList = [];

        },
        fetchVoucherNotesList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/user_billing_type_data', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_VOUCHER_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.voucherPermission = res.data.permissions;
                  this.voucherList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-voucherList'] = parseData;
                    this.voucherList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        allAutoRenewModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            if (!postData.socketData) {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            }
            
            axios.post('/billing/change_auto_renewal_all_option', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                if (!postData.socketData) {
                JsLoadingOverlay.hide();
                }
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        autoRenewModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/change_auto_renewal_option', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  value: postData.value,
                  accountId: postData.accountIdVal,
                  userId: postData.pk
                }
                //commit('CHANGE_BALACE_VALUE', dataList);
                var userId = dataList.userId;
                var value = dataList.value;
                var accountId = dataList.accountId;
                for (var i = 0; i < this.voucherList[accountId + '-voucherList'].length; i++) {
                  if (this.voucherList[accountId + '-voucherList'][i].User.id == userId) {
                    this.voucherList[accountId + '-voucherList'][i].ContactSetting3.accountBalanceCheck = value.reason;
                  }
                }
                this.voucherList = this.voucherList;
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//Scheduledrenewals
        fetchScheduleRenewalsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_schedule_renewals', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_SCHEDULED_RENEWALS_LIST', res.data)
                  var tData = {};
                  var parseData = null;
                  if (res.data != undefined && res.data.message != undefined) {
                    parseData = JSON.parse(res.data.message);
                  }
                  if (res.data != undefined && res.data.permissions != undefined) {
                    this.scheduledRenewalsPermission = res.data.permissions;
                  }
                  this.scheduledRenewalsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-scheduledRenewalsList'] = parseData;
                    this.scheduledRenewalsList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        deleteScheduledRenewalsSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_schedule_renewal', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                var successData = {
                  execId: postData.execId,
                  accountId: postData.accountId
                }
      
                //commit('DELETE_SCHEDULED_RENEWALS_LIST', successData);
                var execsId = successData.execId;
                var accountId = successData.accountId;

                if (Object.keys(this.scheduledRenewalsList).length != 0) {
                  for (var i = 0; i < execsId.length; i++) {
                    var index = this.scheduledRenewalsList[accountId + "-scheduledRenewalsList"].findIndex(sRenewal => sRenewal.ExecuteCommand.id == execsId[i]);
                    this.scheduledRenewalsList[accountId + "-scheduledRenewalsList"].splice(index, 1)
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        startScheduleRenewalModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/schedule_renewal_start', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }            
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteScheduledRenewalModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
      
            axios.post('/billing/delete_schedule_renewal_all', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
      
                }
                var successData = {
                  execId: postData.execId,
                  accountId: postData.accountId
                }
      
                //commit('DELETE_SCHEDULED_RENEWALS_LIST', successData);
                var execsId = successData.execId;
                var accountId = successData.accountId;
            
                if (Object.keys(this.scheduledRenewalsList).length != 0) {
                  for (var i = 0; i < execsId.length; i++) {
                    var index = this.scheduledRenewalsList[accountId + "-scheduledRenewalsList"].findIndex(sRenewal => sRenewal.ExecuteCommand.id == execsId[i]);
                    this.scheduledRenewalsList[accountId + "-scheduledRenewalsList"].splice(index, 1)
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//Plans.vue
        clearPlanState() {
          //commit('CLEAR_PLAN_STATE')
          this.planDetails = [];
          this.billPlanList = [];
          this.billPlanOrderList = [];
          this.billPlanProductCategoriesList = [];
          this.billPlanProductsList = [];
          this.billPlanPeriodsList = [];
          this.billPlanPermissionList = [];
        },
        getPlanTableData(authData) {
          return new Promise((resolve, reject) => {
            axios.get('/billing/get_plan_products_data/' + authData.billPlans)
              .then(response => {
                var successData = {
                  planData: response.data.billPlanPolicyData,
                  accountId: response.data.account_id,
                  method: 'display'
                }
                //commit('ADD_PLAN_POLICY_LIST', successData);
                var planData = successData.planData;
                var accountId = successData.accountId;
                var method = successData.method;
                var planId = successData.planId;

                if (Object.keys(planData).length != 0) {
                  if (method == 'create') {
                    for (const [key, value] of Object.entries(planData)) {
                      if (this.billPlanOrderList[accountId + '-planList'].length != 0) {
                        for (var key2 in this.billPlanOrderList[accountId + '-planList']) {
                          if (Object.keys(this.billPlanOrderList[accountId + '-planList'][key2]).includes(key)) {
                            delete (this.billPlanOrderList[accountId + '-planList'][key2][key]);
                          }
                        }
                      }
                      var newObj = {};
                      newObj[key] = value;
                      this.billPlanOrderList[accountId + '-planList'].push(newObj);
                    }
                  }
                  if (method == 'display') {
                    for (const [key, value] of Object.entries(planData)) {
                      if (Object.keys(this.billPlanOrderList[accountId + '-planList']).includes(key)) {
                        delete (this.billPlanOrderList[accountId + '-planList'][key]);
                      }
                      var newObj = {};
                      newObj[key] = value;
                      var cprop = true;
                      if (this.billPlanOrderList[accountId + '-planList'].length != 0) {
                        for (var key2 in this.billPlanOrderList[accountId + '-planList']) {
                          if (Object.keys(this.billPlanOrderList[accountId + '-planList'][key2]).includes(key)) {
                            cprop = false;
                          }
                        }
                      }
                      if (cprop == true) {
                        this.billPlanOrderList[accountId + '-planList'].push(newObj);
                      }
                    }
                  }
                }
              })
              .catch(error => console.log(error))
          });
        },
        //fetchPlanDetails
        getPlanProductCategories(authData) {
          return new Promise((resolve, reject) => {
            axios.get('/billing/get_product_and_categories/null/true')
              .then(response => {
                //commit('UPDATE_PLAN_PRODUCT_CATEGORIES', response.data);
               this.billPlanProductCategoriesList[response.data.account_id + '-planList']=response.data.productCategories;
               this.billPlanProductsList[response.data.account_id + '-planList']=response.data.products;
               this.billPlanPeriodsList[response.data.account_id + '-planList']=response.data.periods;
 
                resolve(response.data);
              })
              .catch(error => console.log(error))
          });
        },
        updatePlanOrderModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/update_plan_in_orders', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        createPlanModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/create_plan', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  planData: response.data.billPlanData,
                  accountId: response.data.account_id
                }
      
                //commit('ADD_BILL_PLAN_LIST', successData);
                var planData = JSON.parse(successData.planData);
                var accountId = successData.accountId;
            
                if (Object.keys(this.billPlanList).length != 0 && this.billPlanList != 'noData') {
                  if (this.billPlanList != 'noData' && planData.length > 0) {
                    for (var i = 0; i < planData.length; i++) {
                      var index = this.billPlanList[accountId + "-planList"].findIndex(plan => plan.BillPlan.id == planData[i].BillPlan.id);
                      if (index != '-1') {
                        this.billPlanList[accountId + "-planList"][index].BillPlan.name = planData[i].BillPlan.name;
                      } else {
                        this.billPlanList[accountId + "-planList"].push(planData[i]);
                      }
                    }
                  }
                } else {
                  var dataSet = {}
                  dataSet[accountId + "-planList"] = planData;
                  this.billPlanList = dataSet;
                }            
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deletePlanOrderModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_plan_order', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  if (response.data.message == "") {
                    reject(response.data.genericBody);
                  } else {
                    reject(response.data.message);
                  }
                  return;
                }
                var successData = {
                  planIds: response.data.planPolicyIds,
                  accountId: postData.accountId
                }
                //commit('DELETE_PLAN_POLICY_LIST', successData);
                var planData = JSON.parse(successData.planIds);
                var accountId = successData.accountId;
                if (planData != undefined && planData.length > 0) {
                  for (var i = 0; i < planData.length; i++) {
                    var index = this.billPlanOrderList[accountId + '-planList'][0][planData[i].billPlanId].findIndex(plan => plan.id == planData[i].planIds);
                    if (index != '-1') {
                      this.billPlanOrderList[accountId + '-planList'][0][planData[i].billPlanId].splice(index, 1);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteAllPlanModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/delete_plan', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  planIds: response.data.planIds,
                  accountId: postData.accountId
                }
                //commit('DELETE_PLAN_LIST', successData);
                var planIds = successData.planIds;
                var accountId = successData.accountId;
                if (planIds != undefined && planIds.length > 0) {
                  for (var i = 0; i < planIds.length; i++) {
                    var index = this.billPlanList[accountId + '-planList'].findIndex(plan => plan.BillPlan.id == planIds[i]);
                    if (index != '-1') {
                      this.billPlanList[accountId + '-planList'].splice(index, 1);
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        exportPlanSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/export_plans_csv', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        replaceProductSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/replace_product', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        createPlanOrderModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/create_plan_products', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  planData: response.data.billPlanPolicyData,
                  accountId: response.data.account_id,
                  planId: postData.planId,
                  method: 'create'
                }
      
                //commit('ADD_PLAN_POLICY_LIST', successData);
                var planData = successData.planData;
                var accountId = successData.accountId;
                var method = successData.method;
                var planId = successData.planId;

                if (Object.keys(planData).length != 0) {
                  if (method == 'create') {
                    for (const [key, value] of Object.entries(planData)) {
                      if (this.billPlanOrderList[accountId + '-planList'].length != 0) {
                        for (var key2 in this.billPlanOrderList[accountId + '-planList']) {
                          if (Object.keys(this.billPlanOrderList[accountId + '-planList'][key2]).includes(key)) {
                            this.billPlanOrderList[accountId + '-planList'].splice(key2,1);
                          }
                        }
                      }
                      var newObj = {};
                      newObj[key] = value;
                      this.billPlanOrderList[accountId + '-planList'].push(newObj);
                    }
                  }
                  if (method == 'display') {
                    for (const [key, value] of Object.entries(planData)) {
                      if (Object.keys(this.billPlanOrderList[accountId + '-planList']).includes(key)) {
                        delete (this.billPlanOrderList[accountId + '-planList'][key]);
                      }
                      var newObj = {};
                      newObj[key] = value;
                      var cprop = true;
                      if (this.billPlanOrderList[accountId + '-planList'].length != 0) {
                        for (var key2 in this.billPlanOrderList[accountId + '-planList']) {
                          if (Object.keys(this.billPlanOrderList[accountId + '-planList'][key2]).includes(key)) {
                            cprop = false;
                          }
                        }
                      }
                      if (cprop == true) {
                        this.billPlanOrderList[accountId + '-planList'].push(newObj);
                      }
                    }
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        importPlanModal(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/import_plans_file', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  // commit('SET_FAILURE', response.data.message);
                  reject(response.data.message);
                  return;
                }
                var accountId = '';
                for (let [key, val] of postData.entries()) {
                  if (key == 'accountId') {
                    accountId = val;
                    break;
                  }
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        importPlanCopy(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/import_plans_file_copy', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  Nprogress.done();               
                  reject(response.data.message);
                  return;
                }
                var accountId = '';
                for (let [key, val] of postData.entries()) {
                  if (key == 'accountId') {
                    accountId = val;
                    break;
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//Grace period users
        fetchGracePeriodUsersList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/user_grace_period_data', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_GRACE_PERIOD_USERS_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.gracePeriodPermission = res.data.permissions;
                  this.gracePeriodList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-gracePeriodList'] = parseData;
                    this.gracePeriodList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        removeGracePeriodModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/remove', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                console.log(postData);
                var successData = {
                  userDetails: response.data.userDetails,
                  accountId: postData.accountId
                }
                //commit('UPDATE_EXPIRY_REMOVE', successData);
                var userId = successData.userDetails;
                var accountId = successData.accountId;
                for (var i = 0; i < userId.length; i++) {
                  var index = this.gracePeriodList[accountId + "-gracePeriodList"].findIndex(voucher => voucher.User.id == userId[i].User.id);
                  if (index != '-1') {
                    this.gracePeriodList[accountId + "-gracePeriodList"][index].User = userId[i].User;
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
//renewal summary details
        getRenewalTableDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/get_renewal_details', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  resolve(response.data);
                  return;
                }
                resolve(response.data);
              })
      
              .catch(error => console.log(error))
          });
      
        },
        fetchRenewalSummaryList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
           // axios.get('/billing/get_all_renewals_summary/' + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
            axios.post('/billing/get_all_renewals_summary', qs.stringify(authData, {
              parseArrays: false
            })).then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_RENEWAL_SUMMARY_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.renewalSummaryList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-renewalSummaryList'] = parseData;
                    this.renewalSummaryList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
//Pay late user
        fetchPayLaterUsersList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/user_grace_period_prepaid_data', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_PAY_LATER_USERS_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.payLaterUsersPermission = res.data.permissions;
                  this.payLaterUsersList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-payLaterUsersList'] = parseData;
                    this.payLaterUsersList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
//Static ip billing
        fetchStaticIpsBillingList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/get_all_static_ip_billing', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_STATIC_IPS_BILLING_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.staticIpsBillingPermission = res.data.permissions;
                  this.staticIpsBillingList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-staticIpsBillingList'] = parseData;
                    this.staticIpsBillingList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        createStaticIpBilling(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/helpdesk/create_staticIP_billing', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  userDetails: response.data.userDetails,
                  accountId: postData.accountName
                }
                //commit('CREATE_STATICIP_BILLING', successData);
                var userId = successData.userDetails;
                var accountId = successData.accountId;
                if (Object.keys(this.staticIpsBillingList).length != 0) {
                  this.staticIpsBillingList[accountId + "-staticIpsBillingList"].push(userId[0]);
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchUserCreditNotesList( authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/get_all_user_credit_notes/' + authData.accountId + "/" + authData.activeSinceDate + "/" + authData.activeUntilDate + "/" + authData.userId + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  //commit('SET_CREDIT_NOTES_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.creditNotesPermission = res.data.permissions;
                  this.creditNotesList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-creditNotesList'] = parseData;
                    this.creditNotesList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        downloadCreditNotesModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/download_credit_note', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var filePath = "/backend" + response.data.message;
                window.open(filePath);
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchUserInvoicesList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/get_all_user_invoices/' + authData.accountId + "/" + authData.status + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + authData.userId + '/' + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  //commit('SET_INVOICE_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.invoicesPermission = res.data.permissions;
                  this.invoicesList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-invoicesList'] = parseData;
                    this.invoicesList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        sendInvoiceSmsSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/sms_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  if (response.data.status == null || response.data.status == "") {
                    reject("Unable to dispatch SMS.Check for SMS template disable.");
                  } else {
                    reject(response.data.message);
                  }
      
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getOrderDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getOrderDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        generateInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if (postData.method == "generate_proforma_invoice_from_order") {
              axios.post('/users/generate_proforma_invoice_from_order', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            } else {
              axios.post('/users/generate_invoice_from_order', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    orderData: response.data.orderId,
                    accountId: postData.currentAccountId,
                    type: "Generate Invoice From Order"
                  }
                  //commit('UPDATE_ORDER_LIST', successData);
                  var orderData = JSON.parse(successData.orderData);
                  var accountId = orderData.accountId;
                  var account = successData.accountId;
                  var type = successData.type;
                 // console.log("state", state);
                  console.log("orderData", orderData);
                  if (type == "Generate Invoice From Order") {
                    if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                      if (orderData[0].PurchaseOrder.id != undefined || orderData[0].PurchaseOrder.id != null || orderData[0].PurchaseOrder.id != "") {
                        console.log("order", order);
                        var index1 = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData[0].PurchaseOrder.id);
                        console.log("index1", index1);
                        if (index1 != '-1') {
                          this.ordersList[account + "-ordersList"][index1].PurchaseOrder.status_id = orderData[0].PurchaseOrder.status_id;
                          return;
                        }
                      }
                    }
                  }
              
                  if (Object.keys(this.ordersList).length != 0 && this.ordersList != 'noData') {
                    console.log("asdfghjksdfgh");
                    if (orderData.id != undefined || orderData.id != null || orderData.id != "") {
                      //  console.log("order",order.PurchaseOrder.id);
                      console.log("order1", account);
                      console.log("order2", this.ordersList[account + "-ordersList"]);
                      // var temp =JSON.parse(this.ordersList);
                      var index = this.ordersList[account + "-ordersList"].findIndex(order => order.PurchaseOrder.id == orderData.id);
                      // this.ordersList[account + "-ordersList"] = JSON.stringify(temp);
              
                      console.log("qwerty", this.ordersList[account + "-ordersList"]);
                      if (index != '-1') {
                        if (type == "Customer Order Number") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_number = orderData.customer_order_number;
                        } else if (type == "Customer Order Date") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.customer_order_date = orderData.customer_order_date;
                        } else if (type == "Billing Cycle") {
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_value = orderData.due_date_value;
                          this.ordersList[account + "-ordersList"][index].PurchaseOrder.due_date_unit_id = orderData.due_date_unit_id;
                        }
                      }
                    }
                    this.ordersList = this.ordersList;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            }
      
          });
        },
        applyToInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/applyInvoiceToSelected', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        generateOldInvoiceModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/generate_old_invoice_from_order', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        generateOldCreditModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/generate_old_creditnote_from_invoice', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        balanceDueUser(authData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/balance_due_orders', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchUserOrdersList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/get_all_user_orders/' + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + authData.userId + "/" + authData.activeSinceDateFrom + "/" + authData.activeSinceDateTo + "/" + authData.activeUntilDateFrom + "/" + authData.activeUntilDateTo + '/' + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_ORDER_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.ordersPermission = res.data.permissions;
                  this.ordersList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-ordersList'] = parseData;
                    this.ordersList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchBillingConfigList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/billing/get_all_billing_process/' + authData.accountId + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  //commit('SET_BILLING_CONFIG_LIST', res.data)
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.billingConfigPermission = res.data.permissions;
                  this.billingConfigList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-billingConfigList'] = parseData;
                    this.billingConfigList = tData;
                  }
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        setNextBillingDate(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/change_billing_run_date', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.status != 'success') {
                  reject(response.message);
                  return;
                }
                resolve(response.data);
              })
      
              .catch(error => console.log(error))
          });
      
        },
        getBillingIdDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/view_billing_process_details', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getDiagnosticsTableDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/view_report', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
      
              .catch(error => console.log(error))
          });
      
        },
        getImportTableDetails(postData) {
          console.log('clicked');
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/check_users', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
      
              .catch(error => console.log(error))
          });
      
        },
        fetchPinBunchView(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/pin_billing/view_pin_bunch_data/' + authData.bunchId, qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                var tData = {};
                var parseData = JSON.parse(res.data.message);
                this.pinPunchViewPermission = res.data.permissions;
                this.pinBunchViewList = [];
                if (parseData != null) {
                  tData[res.data.accountId + '-pinBunchViewList'] = parseData;
                  this.pinPunchViewList = tData;
                }                
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        getPinDetailsSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/get_pin_details.json', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchPinBunchGroup(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/pin_billing/view_bunch_groups', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                var tData = {};
                var parseData = JSON.parse(res.data.message);
                this.pinPunchGroupPermission = res.data.permissions;
                this.pinPunchGroupList = [];
                if (parseData != null) {
                  tData[res.data.accountId + '-pinBunchGroupList'] = parseData;
                  this.pinPunchGroupList = tData;
                }                
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        bunchDetailsSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/getBunchDetails.json', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        exportBunchSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/export_pins', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status == 'success') {
                  resolve(response.data);
                  window.open("/backend" + response.data.message);
                }
              })
              .catch(error => console.log(error));
          });
        },          
        fetchPinBillingDetail(authData) {
          return new Promise((resolve, reject) => {
            Nprogress.start()
            axios.get('/pin_billing/index/' + true)
              .then(res => {
                Nprogress.done()
                // console.log('Response : ', res);
                var tData = {};
                var parseData = res.data.message;
                this.pinBillingPermission = res.data.permissions;
                this.pinBillingList = [];
                if (parseData != null) {
                  tData[res.data.accountId + '-pinBillingList'] = parseData;
                  this.pinBillingList = tData;
                }                
                resolve(res.data);
      
              })
              .catch(error => console.log(error))
          })
        },
        fetchInvoicesDashboard(authData) {
          Nprogress.start()
          axios.get('/billing/invoices_dashboard_list')
            .then(res => {
              Nprogress.done()
                  //commit('SET_INVOICE_DASHBOARD', res.data)
                  this.invoiceDashboard = res.data

                })
                .catch(error => console.log(error))
        },  
        fetchInvoicesDashboardPayments(postData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/invoice_dashboard_recentpayment', qs.stringify(postData, {
              parseArrays: false
            })).then(response => {
              Nprogress.done()
                resolve(response.data)
              })
              .catch(error => console.log(error));
          });
        },
        fetchFranchiseInvoiceTemplateList() {
          Nprogress.start()
          return new Promise((resolve, reject) => {
          axios.get("/billing/franchise_invoice_template/true")
            .then(response => {
              Nprogress.done()
              if(response.data.status=="error" && response.data.isDatabaseAccessibleCheck=="yes"){
                resolve(response.data);
                return;
              }
              var tData = {};
              var parseData = response.data.message;
              if (Object.keys(this.franchiseInvoiceTemplateList).length == 0) {
                tData[response.data.accountId + '-franchiseInvoiceTemplateList'] = parseData;
                this.franchiseInvoiceTemplateList = tData;
              } else {
                Vue.set(this.franchiseInvoiceTemplateList, response.data.accountId + '-franchiseInvoiceTemplateList', parseData);
              }              
            })
            .catch(error => console.log(error))
          });
        },
        makeDefaultFranchiseInvoiceTemplateSubmit(authData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/make_default_franchise_template/' + authData.gatewayId)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addFranchiseInvoiceTemplateToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/add_franchiseinvoicetemplate_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        deleteFranchiseInvoiceTemplateSubmit(authData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/billing/delete_franchise_invoice_template/' + authData.gatewayId)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var franchiseInvoiceTemplateName = response.data.franchiseInvoiceTemplateName;
                var accountId = response.data.accountId;
                if (Object.keys(this.franchiseInvoiceTemplateList).length != 0) {
                  var index = this.franchiseInvoiceTemplateList[accountId + "-franchiseInvoiceTemplateList"].templates.findIndex(franchiseInvoiceNameTemplate => franchiseInvoiceNameTemplate.franchiseInvoiceTemplateName == franchiseInvoiceTemplateName);
                  if (index != '-1') {
                    this.franchiseInvoiceTemplateList[accountId + "-franchiseInvoiceTemplateList"].templates.splice(index, 1)
                  }
                }                
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchAllPinsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/pin_billing/view_full_data/' + authData.accountId + "/" + authData.status + "/" + true + "/" + authData.limit + "/" + authData.page + "/" + authData.search + "/" + authData.sorting)
              .then(res => {
                Nprogress.done()
                var tData = {};
                var parseData = JSON.parse(res.data.message);
                this.allPinsPermission = res.data.permissions;
                this.allPinsList = [];
                if (parseData != null) {
                  tData[res.data.accountId + '-allPinsList'] = parseData;
                  this.allPinsList = tData;
                }                
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        fetchWhatsappLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/whatsapp_logs_data/' + authData.userId + "/" + authData.accountId + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.smsLogsPermission = res.data.permissions;
                  this.smsLogsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-smsLogsList'] = parseData;
                    this.smsLogsList = tData;
                  }                  
                }
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserVoicePhoneBillingList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/ipphone_billing/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = res.data.message;
                  this.userVoicePhoneBillingPermission = res.data.permissions;
                  this.userVoicePhoneBillingList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userVoicePhoneBillingList'] = parseData;
                    this.userVoicePhoneBillingList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserStaticIpBillingList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/static_ip_billing/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = res.data.message;
                  this.userStaticIpBillingPermission = res.data.permissions;
                  this.userStaticIpBillingList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userStaticIpBillingList'] = parseData;
                    this.userStaticIpBillingList = tData;
                  }                  
                }
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        usageProfileModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/usage_profile', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },       
        fetchPaymentDashboard(authData) {
          Nprogress.start()
          axios.get('/billing/payment_dashboard_list')
            .then(res => {
              Nprogress.done()
              //commit('SET_PAYMENT_DASHBOARD', res.data)
              this.paymentDashboard = res.data
            })
            .catch(error => console.log(error))
        },
        fetchPaymentDashboardPayments(postData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/billing/payment_dashboard_recentpayment/' + postData)
              .then(response => {
                Nprogress.done()
                resolve(response.data)
                console.log('vue resssssss 2', response)
                // if (response.data.status != 'success') {
                //   reject(response.data.message);
                //   return;
                // }
                // resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        getPlanHistoryDetails(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/user_plans/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = res.data.message;
                  this.userPlanHistoryPermission = res.data.permissions;
                  this.userPlanHistoryList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userPlanHistoryList'] = parseData;
                    this.userPlanHistoryList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchIpFiltersList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/userIpDetails/' + authData.userId + "/" + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting + '/' + authData.endDateFrom + '/' + authData.endDateTo)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        getFupHistoryDetails(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/fup_history/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = res.data.message;
                  this.userFupHistoryPermission = res.data.permissions;
                  this.userFupHistoryList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userFupHistoryList'] = parseData;
                    this.userFupHistoryList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchFupFiltersList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/fup_data/' + authData.userId + "/" + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.userFupFilterPermission = res.data.permissions;
                  this.userFupFilterList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-fupFiltersList'] = parseData;
                    this.userFupFilterList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserProformaInvoicesList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/get_all_user_proforma_invoices/' + authData.accountId + "/" + authData.status + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + authData.userId + '/' + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.proformaInvoicesPermission = res.data.permissions;
                  this.proformaInvoicesList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-proformaInvoicesList'] = parseData;
                    this.proformaInvoicesList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserPaymentsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/get_all_user_payments/' + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + authData.userId + '/' + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.paymentsPermission = res.data.permissions;
                  this.paymentsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-paymentsList'] = parseData;
                    this.paymentsList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.post('/users/user_logs_data', qs.stringify(authData, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.userLogsPermission = res.data.permissions;
                  this.userLogsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userLogsList'] = parseData;
                    this.userLogsList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUsersInvoicesPayList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/get_all_user_invoices_associated_with_payments/' + authData.userId + "/" + authData.accountId + "/" + authData.status + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + null + "/" + null + '/' + null + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.invoicesPayPermission = res.data.permissions;
                  this.invoicesPayList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-invoicesPayList'] = parseData;
                    this.invoicesPayList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        getPaymentDetails(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/getPaymentDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        closeIpPhoneSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/closeOrderToSelectedIpphone', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getRate(postData) {
          return new Promise((resolve, reject) => {
            Nprogress.start();
            axios.post('/users/rate', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                Nprogress.done();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        closeIptvSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/closeOrderToSelectedIptv', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        activeUserSession(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/rate', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        closeOttSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/closeOrderToSelectedOtt', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        terminateSession(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/monitoring/terminate_session', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        closeStaticIpSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/closeOrderToSelectedIp', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });    
        },
        fetchCompletedSessionsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/session_data/' + authData.userId + "/" + authData.accountId + "/" + false + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + false + '/' + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.completedUserSessionsPermission = res.data.permissions;
                  this.completedUserSessionsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-completedUserSessionsList'] = parseData;
                    this.completedUserSessionsList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchSubUsersList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/sub_users/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                var tData = {};
                var parseData = res.data.message;
                this.subUsersPermission = res.data.permissions;
                this.subUsersList = [];
                if (parseData != null) {
                  tData[res.data.accountId + '-subUsersList'] = parseData;
                  this.subUsersList = tData;
                }                
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        fetchSmsLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/sms_logs_data/' + authData.userId + "/" + authData.accountId + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.smsLogsPermission = res.data.permissions;
                  this.smsLogsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-smsLogsList'] = parseData;
                    this.smsLogsList = tData;
                  }                  
                }
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        fetchSessionsDayWiseList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/session_data/' + authData.userId + "/" + authData.accountId + "/" + true + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + false + '/' + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.sessionsDayWisePermission = res.data.permissions;
                  this.sessionsDayWiseList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userTrafficList'] = parseData;
                    this.sessionsDayWiseList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserRenewalLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/user_renewal_logs_data/' + authData.userId + "/" + authData.accountId + "/" + authData.status + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                var tData = {};
                var parseData = JSON.parse(res.data.message);
                this.userRenewalLogsPermission = res.data.permissions;
                this.userRenewalLogsList = [];
                if (parseData != null) {
                  tData[res.data.accountId + '-userRenewalLogsList'] = parseData;
                  this.userRenewalLogsList = tData;
                }                
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        paymentDetailsModalSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/logs/getPaymentLogDetails', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        reVerifyTransactionDetailsModalSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/logs/reVerifyTransactionStatus', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchPaymentLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/payment_logs_data/' + authData.userId + "/" + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.paymentLogsPermission = res.data.permissions;
                  this.paymentLogsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-paymentLogsList'] = parseData;
                    this.paymentLogsList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserOttBillingList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/ott_billing/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = res.data.message;
                  this.userOttBillingPermission = res.data.permissions;
                  this.userOttBillingList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userOttBillingList'] = parseData;
                    this.userOttBillingList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchUserIptvBillingList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/iptv_billing/' + authData.userId + "/" + authData.accountId + "/" + true)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = res.data.message;
                  this.userIptvBillingPermission = res.data.permissions;
                  this.userIptvBillingList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-userIptvBillingList'] = parseData;
                    this.userIptvBillingList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        fetchEventLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/event_logs_data/' + authData.userId + "/" + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + authData.type + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.eventLogsPermission = res.data.permissions;
                  this.eventLogsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-eventLogsList'] = parseData;
                    this.eventLogsList = tData;
                  }                  
                }
                resolve(res.data);
              })
              .catch(error => console.log(error))
          });
        },
        fetchEmailLogsList(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get('/users/email_logs_data/' + authData.userId + "/" + authData.accountId + "/" + authData.customDateFrom + "/" + authData.customDateTo + "/" + true + '/' + authData.limit + '/' + authData.page + '/' + authData.search + '/' + authData.sorting)
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                } else {
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.emailLogsPermission = res.data.permissions;
                  this.emailLogsList = [];
                  if (parseData != null) {
                    tData[res.data.accountId + '-emailLogsList'] = parseData;
                    this.emailLogsList = tData;
                  }                  
                  resolve(res.data);
                }
              })
              .catch(error => console.log(error))
          });
        },
        unlinkUserSubmit(postData) {
          return new Promise((resolve, reject) => {    
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var accountID = postData.accountID;
            axios.post('/users/unlink_sub_user/' + accountID, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var data = {
                  accountId: postData.accountID,
                  userDetails: response.data.userDetails
                }
                var userDetails = data.userDetails;
                var accountId = data.accountId;
                if (Object.keys(this.subUsersList).length != 0) {
                  for (var i = 0; i < userDetails.length; i++) {
                    var index = this.subUsersList[accountId + "-subUsersList"].linkedUsersDetails.findIndex(link => link.User.id == userDetails[i].User.id);
                    if (index != "-1") {
                      this.subUsersList[accountId + "-subUsersList"].linkedUsersDetails.splice(index, 1);
                    }
            
                  }
                }  
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },       
        getDetailsOfCreatePins(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/pin_billing/add_pin_group/true')
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        postDetailsOfCreatePins(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/add_pin_group', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },   
        usePinModalSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/pin_valid', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteBunchModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/delete_bunch', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  accountId: postData.presentAccId,
                  bunchId: postData.bunchId,
      
                }
                //commit('DELETE_PIN_BUNCH', successData);
                var bunchId = successData.bunchId;
                var accountId = successData.accountId;
                var index = this.pinPunchGroupList[accountId + "-pinBunchGroupList"].findIndex(pin => pin.Pin.bunch_id == bunchId);
                if (index != '-1') {
                  this.pinPunchGroupList[accountId + "-pinBunchGroupList"].splice(index, 1)
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        sellBunchModalSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/sell_pins.json', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  accountId: postData.presentAccId,
                  updateStatus: response.data.updatedStatusOfThePins,
      
                }
                //commit('UPDATE_STATUS_PINBUNCH', successData);
                var updatedPins = successData.updateStatus;
                var accountId = successData.accountId;
                if (updatedPins.length > 0) {
                  for (var i = 0; i < updatedPins.length; i++) {
                    this.pinPunchGroupList[accountId + "-pinBunchGroupList"][i].Pin.status = updatedPins[i].Pin.status;
                    this.pinPunchGroupList[accountId + "-pinBunchGroupList"][i].Pin.sold_to = updatedPins[i].Pin.sold_accountId;
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },

        deletePinsModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/delete_pingroup/' + postData.groupid, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },

        fetchNotificationDetails(authData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {
            axios.get(authData.url)
              .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push("/dashboard");
              }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                resolve(res.data);
              } else {
                var tData = {};
                var parseData = res.data.message;
                if (Object.keys(this.notificationDetails).length == 0) {
                  tData[res.data.accountId + '-notificationList'] = parseData;
                  this.notificationDetails = tData;
                } else {
                  Vue.set(this.notificationDetails, res.data.accountId + '-notificationList', parseData);
                }                
              }      
            })
            .catch(error => console.log(error))
          });
        },
        saveBillingNotifications(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post(postData.url, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.alertText);
              })
              .catch(error => console.log(error));
          });
        },
        deleteIptvBillingSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/delete_iptv_in_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteIpPhoneBillingSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/delete_ipphone_in_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addNotificationToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/notifications/add_notifications_to_all_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        deleteOttBillingSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/delete_ott_in_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        saveSubAccountNotificationSettings(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/notifications/save_sub_accounts_cannot_change_notifications', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                //commit('SET_RESTRICTION_CHECKBOX_VALUE', postData);
                if (postData.switchCheckBoxValue == 'on') {
                  // Vue.set(this.notificationDetails[postData.accountId + '-notificationList'], 'subAccountsCanNotChangeBillingNotifications', 'Yes');
                  this.notificationDetails[postData.accountId + '-notificationList']='subAccountsCanNotChangeBillingNotifications', 'Yes';

                } else {

                  // Vue.set(this.notificationDetails[postData.accountId + '-notificationList'], 'subAccountsCanNotChangeBillingNotifications', 'No');
                  this.notificationDetails[postData.accountId + '-notificationList']='subAccountsCanNotChangeBillingNotifications', 'No';

                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        deleteStaticIpSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/delete_static_ip_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getSubUserNameList(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var accountID = postData.accountID;
            var userId = postData.userId;
            axios.post('/users/get_account_users/' + userId + '/' + accountID, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var data = {
                  message: response.data.message,
                  accountId: postData.accountID
                }
                //commit('LINK_SUBUSER_LIST', data);
                var message = data.message;
                var accountId = data.accountId;
                if (Object.keys(this.subUsersList).length != 0) {
                  for (var i = 0; i < message.length; i++) {
                    this.subUsersList[accountId + "-subUsersList"].users[i] = message[i];
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        destroyBunchModalSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/delete_pins.json', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  accountId: postData.presentAccId,
                  bunchId: postData.bunchId,
      
                }
                //commit('DESTROY_PIN_BUNCH', successData);
                var bunchId = successData.bunchId;
                var accountId = successData.accountId;
                var pinBunchLength = this.pinPunchGroupList[accountId + "-pinBunchGroupList"].length;
                for (var i = 0; i < pinBunchLength; i++) {
                  if (this.pinPunchGroupList[accountId + "-pinBunchGroupList"][i].Pin.bunch_id == bunchId) {
                    this.pinPunchGroupList[accountId + "-pinBunchGroupList"][i].Pin.status = 'deleted';
                  }
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        linkUserSubmit(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var accountID = postData.accountID;
            axios.post('/users/sub_users/null/' + accountID + "/false", qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var data = {
                  accountId: postData.accountID,
                  userDetails: response.data.userDetails
                }
                //commit('ADD_SUBUSER_LIST', data);
                var userDetails = data.userDetails;
                var accountId = data.accountId;
                if (Object.keys(this.subUsersList).length != 0 && this.subUsersList[accountId + "-subUsersList"] !=undefined && this.subUsersList[accountId + "-subUsersList"].linkedUsersDetails != undefined) {
                  this.subUsersList[accountId + "-subUsersList"].linkedUsersDetails.push(userDetails[0]);
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        updateStaticIpList(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/update_static_ip', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                var successData = {
                  staticIpDetails: response.data.staticIpDetails,
                  accountId: postData.accountId
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        generatePinsModalSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/generate_pins', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addEditFranchiseInvoiceTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/franchiseInvoiceTemplateSubmit', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  templates: response.data.templates,
                  accountId: response.data.accountId
                }
                // commit('ADD_EDIT_FRANCHISE_INVOICE_TEMPLATE_LIST', successData);
                var data=successData;
                var templates = data.templates;
                var accountId = data.accountId;
                this.franchiseInvoiceTemplateList[accountId + '-franchiseInvoiceTemplateList']['templates']= templates;
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        getDetailsOfEditPins(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.get('/pin_billing/edit_pin_groups/' + postData.groupid + '/true')
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addOrderToIptvBilling(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/applyOrderToIPTVBilling', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        applyOrderToIPPhoneBilling(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/applyOrderToIPPhoneBilling', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        applyOrderToOttBilling(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/applyOrderToOttBilling', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.genericBody);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addOrderSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/applyOrderToSelectedIp', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addIptvSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/add_iptv_in_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addIpPhoneSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/add_ipphone_in_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },          
        addOttSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/add_ott_in_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        postDetailsOfEditPins(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/pin_billing/post_edit_pin_groups/' + postData.groupid, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },         
        addStaticIpSubmit(postData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/users/add_static_ip_billing_cycle', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchLedgerTemplateList() {
          Nprogress.start()
          axios.get("/billing/ledger_template/true")
              .then(response => {
              Nprogress.done()
              if (response.data.status == "redirect") {
                  router.push("/dashboard");
              } else {
                  var tData = {};
                  var parseData = response.data.message;
                  if (Object.keys(this.ledgerTemplateList).length == 0) {
                      tData[response.data.accountId + '-ledgerTemplateList'] = parseData;
                      this.ledgerTemplateList = tData;
                  } else {
                      Vue.set(this.ledgerTemplateList, response.data.accountId + '-ledgerTemplateList', parseData);
                  }
              }
              })
              .catch(error => console.log(error))
        },
        addEditLedgerTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/ledgerTemplateSubmit', postData)
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  templates: response.data.templates,
                  accountId: response.data.accountId
                }
                var templates = successData.templates;
                var accountId = successData.accountId;
                this.ledgerTemplateList[accountId + '-ledgerTemplateList']['templates']=templates;
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        makeDefaultLedgerTemplateSubmit(authData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.get('/billing/make_default_ledger_template/' + authData.gatewayId)
              .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                  }
                  resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteLedgerTemplateSubmit(authData) {
          return new Promise((resolve, reject) => {
      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.get('/billing/delete_ledger_template/' + authData.gatewayId)
              .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                  }
                  var successData = {
                  ledgerData: response.data.ledgerTemplateName,
                  accountId: response.data.accountId
                  }
                  var ledgerTemplateName = successData.ledgerData;
                  var accountId = successData.accountId;
                  if (Object.keys(this.ledgerTemplateList).length != 0) {
                  var index = this.ledgerTemplateList[accountId + "-ledgerTemplateList"].templates.findIndex(ledgerTemplate => ledgerTemplate.ledgerTemplateName == ledgerTemplateName);
                  if (index != '-1') {
                      this.ledgerTemplateList[accountId + "-ledgerTemplateList"].templates.splice(index, 1)
                  }
                  }
                  resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addLedgerTemplateToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/billing/add_ledgertemplate_subaccounts', qs.stringify(postData, {
              parseArrays: false
              }))
              .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                  }
                  resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        receiptNumberEdit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/billing/edit_payment_receipt_number', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var paymentData = response.data.returnData;
                var accountId = paymentData.accountId;
                if (Object.keys(this.paymentsList).length != 0 && this.paymentsList != 'noData') {
                  if (paymentData.id != undefined || paymentData.id != null || paymentData.id != "") {
                    var index = this.paymentsList[accountId + "-paymentsList"].findIndex(payment => payment.Payment.id == paymentData.id);
                    if (index != '-1') {
                      this.paymentsList[accountId + "-paymentsList"][index].Payment.public_number = paymentData.public_number;
                    }
                  }
                  this.paymentsList = this.paymentsList;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
    }
});