<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <div class="unifiSettingsHeader unifiSettingsHeader--withTabs noBorder" tabs="settingsSubMenuRoutingAndFirewallCtrl.tabs">
          <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
            <li @click="switchTabs('invoice')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'invoice'}" v-if="invoiceData!=undefined && invoiceData.permissions!=undefined && invoiceData.permissions.Billing==true">
              <span>Invoice </span>
            </li>
            <li @click="switchTabs('proformaInvoice')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'proformaInvoice'}" v-if="invoiceData!=undefined && invoiceData.permissions!=undefined && invoiceData.permissions.Billing==true">
              <span>Proforma Invoice </span>
            </li>
            <li @click="switchTabs('payment')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'payment'}" v-if="invoiceData!=undefined && invoiceData.permissions!=undefined && invoiceData.permissions.Billing==true">
              <span>Payment </span>
            </li>
            <li @click="switchTabs('username')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'username'}">
              <span>User Name </span>
            </li>
            <li @click="switchTabs('circuitId')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'circuitId'}">
              <span v-if="invoiceData.edition=='isp'">Circuit Id </span>
              <span v-else>Serial Number </span>
            </li>
            <li @click="switchTabs('lead')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'lead'}">
              <span>Lead </span>
            </li>
            <li @click="switchTabs('helpdesk')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'helpdesk'}">
              <span>Helpdesk </span>
            </li>
            <li @click="switchTabs('creditNote')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'creditNote'}">
              <span>Credit Notes </span>
            </li>
            <li @click="switchTabs('caf')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'caf'}">
              <span>CAF </span>
            </li>
          </ul>
        </div>
        <div v-if="selectedTab == 'invoice'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTab='invoicePrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTab == 'invoicePrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTab='invoiceNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTab == 'invoiceNum'}">
              <span>Invoice Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTab == 'invoicePrefix'">
            <div class="unifiSettingsHeader" v-if="invoiceData.permissions!=undefined && invoiceData.permissions.create_invoice_prefixes==true">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('Invoice')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                      <button v-if="invoiceData != undefined && invoiceData.invoiceNumTypeValue != undefined && invoiceData.invoiceNumTypeValue == 'yes'" class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('BusinessInvoice')">
                        <span class="jaze-icon--plus"></span> <span> Add Business Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for invoice</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="invoiceData.prefixIn != undefined && invoiceData.prefixIn.length !=0" v-for="prefix in invoiceDataUpdated">
                      <td>
                        <span> {{prefix.InvoicePrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.InvoicePrefix.start_date_vue != undefined"> {{prefix.InvoicePrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'Invoice')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="invoiceData != undefined && invoiceData.invoiceNumTypeValue != undefined && invoiceData.invoiceNumTypeValue == 'yes' && invoiceData.prefixBusinessIn != undefined && invoiceData.prefixBusinessIn.length !=0" >
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Business Invoice</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-for="prefix in businessInvoiceDataUpdated" :key="prefix">
                      <td>
                        <span> {{prefix.InvoicePrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.InvoicePrefix.start_date_vue != undefined"> {{prefix.InvoicePrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'BusinessInvoice')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTab == 'invoiceNum'">
            <!-- <div class="unifiSettingsHeader" v-if="invoiceData.addInvoiceNumber == 'yes' && invoiceData.selectedInvoiceNumber!=undefined && Object.keys(invoiceData.selectedInvoiceNumber).length == 0"> -->
            <div class="unifiSettingsHeader" v-if="invoiceData.addInvoiceNumber == 'yes' && invoiceData.selectedInvoiceNumber!=undefined">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('Invoice')">
                        <span class=" jaze-icon--plus"></span> <span> Change Invoice Number </span>
                      </button>
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" v-if="invoiceData != undefined && invoiceData.invoiceNumTypeValue != undefined && invoiceData.invoiceNumTypeValue == 'yes'" type="button" @click="addNumber('BusinessInvoice')">
                        <span class=" jaze-icon--plus"></span> <span> Change Business Invoice Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form ref="addEditBandwidthForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="form-group appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Separate Invoice Number for Home and Business Users</label>
                </div><br/>
                <div class="col--sm7 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="invoiceNumberBasedOnUserType" v-model="invoiceNumberBasedOnUserType" true-value="on" false-value="off"/>
                </div>
              </div>
            </form>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for invoice pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsInvoice" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <div class="appRow" v-if="invoiceNumberBasedOnUserType=='on'">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for business invoice pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsBusinessInvoice" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="invoiceData.selectedInvoiceNumber!=undefined && Object.keys(invoiceData.selectedInvoiceNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Invoice Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-for='(invNumber,index) in invoiceData.selectedInvoiceNumber' :key='index'>
                      <td>
                        <span> {{invNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{invNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Invoice',index)">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Invoice',index)">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="invoiceData != undefined && invoiceData.invoiceNumTypeValue != undefined && invoiceData.invoiceNumTypeValue == 'yes' && invoiceData.selectedBusinessInvoiceNumber!=undefined && Object.keys(invoiceData.selectedBusinessInvoiceNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Business Invoice Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-for='(invNumber,index) in invoiceData.selectedBusinessInvoiceNumber' :key='index'>
                      <td>
                        <span> {{invNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{invNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('BusinessInvoice',index)">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('BusinessInvoice',index)">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'proformaInvoice'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabProforma='proformaPrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabProforma == 'proformaPrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabProforma='proformNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabProforma == 'proformNum'}">
              <span>Proforma Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabProforma == 'proformaPrefix'">
            <div class="unifiSettingsHeader" v-if="invoiceData.permissions!=undefined && invoiceData.permissions.create_invoice_prefixes==true">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('Proforma Invoice')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                      <button v-if="proformaInvoiceData != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue == 'yes'" class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('BusinessProformaInvoice')">
                        <span class="jaze-icon--plus"></span> <span> Add Business Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Proforma invoice</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="proformaInvoiceData.prefixIn != undefined && proformaInvoiceData.prefixIn.length !=0" v-for="prefix in proformaInvoiceDataUpdated">
                      <td>
                        <span> {{prefix.ProformaInvoicePrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.ProformaInvoicePrefix.start_date_vue != undefined"> {{prefix.ProformaInvoicePrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'Proforma Invoice')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="proformaInvoiceData != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue == 'yes' && proformaInvoiceData.prefixBusinessIn != undefined && proformaInvoiceData.prefixBusinessIn.length !=0" >
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Business Proforma invoice</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-for="prefix in businessProformaInvoiceDataUpdated" :key="prefix">
                      <td>
                        <span> {{prefix.ProformaInvoicePrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.ProformaInvoicePrefix.start_date_vue != undefined"> {{prefix.ProformaInvoicePrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'BusinessProformaInvoice')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabProforma == 'proformNum'">
            <!-- <div class="unifiSettingsHeader" v-if="proformaInvoiceData.addProformaNumber == 'yes' && proformaInvoiceData.selectedProformaNumber!=undefined && Object.keys(proformaInvoiceData.selectedProformaNumber).length == 0"> -->
            <div class="unifiSettingsHeader" v-if="proformaInvoiceData.addProformaNumber == 'yes' && proformaInvoiceData.selectedProformaNumber!=undefined">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('Proforma Invoice')">
                        <span class=" jaze-icon--plus"></span> <span> Change Proforma Number </span>
                      </button>
                      <button v-if="proformaInvoiceData != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue == 'yes'" class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('BusinessProformaInvoice')">
                        <span class=" jaze-icon--plus"></span> <span> Change Business Proforma Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form ref="addEditBandwidthForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="form-group appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Separate Proforma Invoice Number for Home and Business Users</label>
                </div><br/>
                <div class="col--sm7 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="proformaInvoiceNumberBasedOnUserType" v-model="proformaInvoiceNumberBasedOnUserType" true-value="on" false-value="off"/>
                </div>
              </div>
            </form>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for proforma invoice pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsProformaInvoice" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <div class="appRow" v-if="proformaInvoiceNumberBasedOnUserType=='on'">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for business proforma invoice pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsBusinessProformaInvoice" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="proformaInvoiceData.selectedProformaNumber!=undefined && Object.keys(proformaInvoiceData.selectedProformaNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Proforma Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{proformaInvoiceData.selectedProformaNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{proformaInvoiceData.selectedProformaNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Proforma Invoice')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Proforma Invoice')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="proformaInvoiceData != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue != undefined && proformaInvoiceData.proformaInvoiceNumTypeValue == 'yes' && proformaInvoiceData.selectedBusinessProformaInvoiceNumber!=undefined && Object.keys(proformaInvoiceData.selectedBusinessProformaInvoiceNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>BusinessProformaInvoice Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{proformaInvoiceData.selectedBusinessProformaInvoiceNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{proformaInvoiceData.selectedBusinessProformaInvoiceNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('BusinessProformaInvoice')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('BusinessProformaInvoice')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'payment'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabPayment='paymentPrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabPayment == 'paymentPrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabPayment='paymentNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabPayment == 'paymentNum'}">
              <span>Payment Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabPayment == 'paymentPrefix'">
            <div class="unifiSettingsHeader" v-if="invoiceData.permissions!=undefined && invoiceData.permissions.create_invoice_prefixes==true">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('Payment')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                      <button v-if="paymentData != undefined && paymentData.paymentNumTypeValue != undefined && paymentData.paymentNumTypeValue == 'yes'" class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('BusinessPayment')">
                        <span class="jaze-icon--plus"></span> <span> Add Business Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Payment</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="paymentData.prefixIn != undefined && paymentData.prefixIn.length !=0" v-for="prefix in paymentDataUpdated">
                      <td>
                        <span> {{prefix.PaymentPrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.PaymentPrefix.start_date_vue != undefined"> {{prefix.PaymentPrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'Payment')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="paymentData != undefined && paymentData.paymentNumTypeValue != undefined && paymentData.paymentNumTypeValue == 'yes' && paymentData.prefixBusinessIn != undefined && paymentData.prefixBusinessIn.length !=0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for BusinessPayment</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr  v-for="prefix in businessPaymentDataUpdated" :key="prefix">
                      <td>
                        <span> {{prefix.PaymentPrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.PaymentPrefix.start_date_vue != undefined"> {{prefix.PaymentPrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'BusinessPayment')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabPayment == 'paymentNum'">
            <!-- <div class="unifiSettingsHeader" v-if="paymentData.addPaymentNumber == 'yes' && paymentData.selectedPaymentNumber!=undefined && Object.keys(paymentData.selectedPaymentNumber).length == 0"> -->
              <div class="unifiSettingsHeader" v-if="paymentData.addPaymentNumber == 'yes' && paymentData.selectedPaymentNumber!=undefined">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('Payment')">
                        <span class=" jaze-icon--plus"></span> <span> Change Payment Number </span>
                      </button>
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" v-if="paymentData != undefined && paymentData.paymentNumTypeValue != undefined && paymentData.paymentNumTypeValue == 'yes'" type="button" @click="addNumber('BusinessPayment')">
                        <span class=" jaze-icon--plus"></span> <span> Change Business Payment Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form ref="addEditBandwidthForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="form-group appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Separate Payment Number for Home and Business Users</label>
                </div><br/>
                <div class="col--sm7 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="paymentNumberBasedOnUserType" v-model="paymentNumberBasedOnUserType" true-value="on" false-value="off"/>
                </div>
              </div>
            </form>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for payment pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsPayment" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <div class="appRow" v-if="paymentNumberBasedOnUserType=='on'">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for business payment pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsBusinessPayment" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="paymentData.selectedPaymentNumber!=undefined && Object.keys(paymentData.selectedPaymentNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Payment Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{paymentData.selectedPaymentNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{paymentData.selectedPaymentNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Payment')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Payment')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="paymentData != undefined && paymentData.paymentNumTypeValue != undefined && paymentData.paymentNumTypeValue == 'yes' && paymentData.selectedBusinessPaymentNumber!=undefined && Object.keys(paymentData.selectedBusinessPaymentNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Business Payment Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{paymentData.selectedBusinessPaymentNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{paymentData.selectedBusinessPaymentNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('BusinessPayment')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('BusinessPayment')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'username'">
          <form class="appForm appForm--cozy is-validation-hidden">
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">User Name Prefix</label>
                  </div>
                  <div class="col--sm7 appFormGroup appFormGroup--cozy">
                    <input class="input--full " type="text" name="companyName" v-model="usrNamePrefix">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Select accounts for username prefix pool</label>
                  </div>
                  <div class="col--sm7 appFormGroup appFormGroup--cozy" v-if='selectedAccsUsernameDis == true'>
                    <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsUsername" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div v-if="selectedTab == 'circuitId'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabCircuit='circuitPrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabCircuit == 'circuitPrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabCircuit='circuitNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabCircuit == 'circuitNum'}">
              <span>Circuit Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabCircuit == 'circuitPrefix'">
            <div class="unifiSettingsHeader">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('Circuit Id')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Circuit Id</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="circuitIdData.prefixIn != undefined && circuitIdData.prefixIn.length !=0" v-for="prefix in circuitIdDataUpdated">
                      <td>
                        <span v-if="prefix.CircuitIdPrefix.prefix=='#jaze#-'"> </span>
                        <span v-else> {{prefix.CircuitIdPrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.CircuitIdPrefix.start_date_vue != undefined"> {{prefix.CircuitIdPrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'Circuit Id')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabCircuit == 'circuitNum'">
            <div class="unifiSettingsHeader" v-if="circuitIdData.addCircuitNumber == 'yes' && circuitIdData.selectedCircuitNumber!=undefined && Object.keys(circuitIdData.selectedCircuitNumber).length == 0">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('Circuit Id')">
                        <span class=" jaze-icon--plus"></span> <span> Change Circuit Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for circuit id pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsCircuitId" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="circuitIdData.selectedCircuitNumber!=undefined && Object.keys(circuitIdData.selectedCircuitNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Circuit Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{circuitIdData.selectedCircuitNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{circuitIdData.selectedCircuitNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Circuit Id')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Circuit Id')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'lead'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabLead='leadPrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabLead == 'leadPrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabLead='leadNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabLead == 'leadNum'}">
              <span>Lead Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabLead == 'leadPrefix'">
            <div class="unifiSettingsHeader">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('Lead')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Lead</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="leadData.prefixIn != undefined && leadData.prefixIn.length !=0" v-for="prefix in leadDataUpdated">
                      <td>
                        <span> {{prefix.LeadPrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.LeadPrefix.start_date_vue != undefined"> {{prefix.LeadPrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'Lead')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabLead == 'leadNum'">
            <div class="unifiSettingsHeader" v-if="leadData.addLeadNumber == 'yes' && leadData.selectedLeadNumber!=undefined && Object.keys(leadData.selectedLeadNumber).length == 0">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('Lead')">
                        <span class=" jaze-icon--plus"></span> <span> Change Lead Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for Lead pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsLead" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="leadData.selectedLeadNumber!=undefined && Object.keys(leadData.selectedLeadNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Lead Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{leadData.selectedLeadNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{leadData.selectedLeadNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Lead')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Lead')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'helpdesk'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabHelpdesk='helpdeskPrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabHelpdesk == 'helpdeskPrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabHelpdesk='helpdeskNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabHelpdesk == 'helpdeskNum'}">
              <span>HelpDesk Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabHelpdesk == 'helpdeskPrefix'">
            <div class="unifiSettingsHeader">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('HelpDesk')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for HelpDesk</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="helpdeskData.prefixIn != undefined && helpdeskData.prefixIn.length !=0" v-for="prefix in helpdeskDataUpdated">
                      <td>
                        <span> {{prefix.HelpDeskPrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.HelpDeskPrefix.start_date_vue != undefined"> {{prefix.HelpDeskPrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'HelpDesk')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabHelpdesk == 'helpdeskNum'">
            <div class="unifiSettingsHeader" v-if="helpdeskData.addHelpDeskNumber == 'yes' && helpdeskData.selectedHelpDeskNumber!=undefined && Object.keys(helpdeskData.selectedHelpDeskNumber).length == 0">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('HelpDesk')">
                        <span class=" jaze-icon--plus"></span> <span> Change HelpDesk Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for HelpDesk pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsHelpdesk" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="helpdeskData.selectedHelpDeskNumber!=undefined && Object.keys(helpdeskData.selectedHelpDeskNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Helpdesk Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{helpdeskData.selectedHelpDeskNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{helpdeskData.selectedHelpDeskNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Helpdesk')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Helpdesk')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'creditNote'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabCreditNote='creditNotePrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabCreditNote == 'creditNotePrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabCreditNote='creditNoteNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabCreditNote == 'creditNoteNum'}">
              <span>Credit Note Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabCreditNote == 'creditNotePrefix'">
            <div class="unifiSettingsHeader" v-if="invoiceData.permissions!=undefined && invoiceData.permissions.create_invoice_prefixes==true">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('CreditNote')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                      <button v-if="creditNoteData != undefined && creditNoteData.creditNoteNumTypeValue != undefined && creditNoteData.creditNoteNumTypeValue == 'yes'" class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('BusinessCreditNote')">
                        <span class="jaze-icon--plus"></span> <span> Add Business Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Credit Note</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="creditNoteData.prefixIn != undefined && creditNoteData.prefixIn.length !=0" v-for="prefix in creditNoteDataUpdated">
                      <td>
                        <span> {{prefix.CreditNotePrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.CreditNotePrefix.start_date_vue != undefined"> {{prefix.CreditNotePrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'CreditNote')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="creditNoteData != undefined && creditNoteData.creditNoteNumTypeValue != undefined && creditNoteData.creditNoteNumTypeValue == 'yes' && creditNoteData.prefixBusinessIn != undefined && creditNoteData.prefixBusinessIn.length !=0" >
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for Business Credit Note</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-for="prefix in businessCreditNoteDataUpdated" :key="prefix">
                      <td>
                        <span> {{prefix.CreditNotePrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.CreditNotePrefix.start_date_vue != undefined"> {{prefix.CreditNotePrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'BusinessCreditNote')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabCreditNote == 'creditNoteNum'">
            <!-- <div class="unifiSettingsHeader" v-if="creditNoteData.addPaymentNumber == 'yes' && creditNoteData.selectedPaymentNumber!=undefined && Object.keys(creditNoteData.selectedPaymentNumber).length == 0"> -->
            <div class="unifiSettingsHeader" v-if="creditNoteData.addPaymentNumber == 'yes' && creditNoteData.selectedPaymentNumber!=undefined">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('CreditNote')">
                        <span class=" jaze-icon--plus"></span> <span> Change Credit Note Number </span>
                      </button>
                      <button v-if="creditNoteData != undefined && creditNoteData.creditNoteNumTypeValue != undefined && creditNoteData.creditNoteNumTypeValue == 'yes'" type="button" @click="addNumber('BusinessCreditNote')" class="appMainButton appMainButton--secondary appMainButton--cozy">
                        <span class=" jaze-icon--plus"></span> <span> Change Business Credit Note Number </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form ref="addEditBandwidthForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="form-group appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Separate Credit Note Number for Home and Business Users</label>
                </div><br/>
                <div class="col--sm7 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="creditNoteNumberBasedOnUserType" v-model="creditNoteNumberBasedOnUserType" true-value="on" false-value="off"/>
                </div>
              </div>
            </form>
            <div class="appRow">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for Credit Note pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsCreditNote" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <div class="appRow" v-if="creditNoteNumberBasedOnUserType=='on'">
              <div class="col--sm3">
                <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for business Credit Note pool</label>
              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsBusinessCreditNote" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet" v-if="creditNoteData.selectedPaymentNumber!=undefined && Object.keys(creditNoteData.selectedPaymentNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Credit Note Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{creditNoteData.selectedPaymentNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{creditNoteData.selectedPaymentNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('CreditNote')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('CreditNote')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
            <fieldset class="appFieldset appFieldset--quiet" v-if="creditNoteData != undefined && creditNoteData.creditNoteNumTypeValue != undefined && creditNoteData.creditNoteNumTypeValue == 'yes' && creditNoteData.selectedBusinessCreditNoteNumber!=undefined && Object.keys(creditNoteData.selectedBusinessCreditNoteNumber).length != 0">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Business Credit Note Number</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr>
                      <td>
                        <span> {{creditNoteData.selectedBusinessCreditNoteNumber.number}} </span>
                      </td>
                      <td>
                        <span> {{creditNoteData.selectedBusinessCreditNoteNumber.startDate}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('BusinessCreditNote')">
                              <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                            </button>
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('BusinessCreditNote')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="selectedTab == 'caf'">
          <ul class="appSubtabs appSubtabs--responsive unifiSettingsSubtabs">
            <li @click="selectedSubTabCAF='cafPrefix'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabCAF == 'cafPrefix'}">
              <span>Prefix</span>
            </li>
            <li @click="selectedSubTabCAF='cafNum'" class="tabs__item noPaddingTop" :class="{'is-tab-selected':selectedSubTabCAF == 'cafNum'}">
              <span>Caf Number</span>
            </li>
          </ul>
          <div v-if="selectedSubTabCAF == 'cafPrefix'">
            <div class="unifiSettingsHeader" v-if="invoiceData.permissions!=undefined && invoiceData.permissions.create_invoice_prefixes==true">
              <div class="unifiSettingsHeader__left">
              </div>
              <div class="unifiSettingsHeader__right" v-if="getTheCurrentPrefix.visible == 'visible'">
                <div class="appForm appForm--cozy">
                  <div class="appInputGroup">
                    <div class="appInputGroup inlineBlock">
                      <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addPrefix('CAF')">
                        <span class="jaze-icon--plus"></span> <span> Add Prefix </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Prefix for CAF</span>
                      </th>
                      <th>
                        <span>Start Date</span>
                      </th>
                      <th>
                      </th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="cafData.prefixIn != undefined && cafData.prefixIn.length !=0" v-for="prefix in cafDataUpdated">
                      <td>
                        <span> {{prefix.CafPrefix.prefix}} </span>
                      </td>
                      <td>
                        <span v-if="prefix.CafPrefix.start_date_vue != undefined"> {{prefix.CafPrefix.start_date_vue}} </span>
                      </td>
                      <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0" v-if="getTheCurrentPrefix.visible == 'visible'">
                        <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                          <div class="jazeButtonGroup">
                            <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deletePrefix(prefix, 'CAF')">
                              <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table><br><br>
              </div>
            </fieldset>
          </div>
          <div v-if="selectedSubTabCAF == 'cafNum'">
            <form v-if="cafData.addCafNumber == 'yes' && cafData.selectedCafNumber!=undefined" class="appForm appForm--cozy is-validation-hidden">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Automatic caf number generation</label>
                </div>
                <div class="col--sm7 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="automaticCafGeneration" v-model="automaticCafGeneration" @change="updateAutoCaf()">
                </div>
              </div>
            </form>
            <div v-show="cafData.automaticCafGeneration==true && automaticCafGeneration">
              <div class="unifiSettingsHeader" v-if="cafData.addCafNumber == 'yes' && cafData.selectedCafNumber!=undefined">
                <div class="unifiSettingsHeader__left">
                </div>
                <div class="unifiSettingsHeader__right">
                  <div class="appForm appForm--cozy">
                    <div class="appInputGroup">
                      <div class="appInputGroup inlineBlock">
                        <button class="appMainButton appMainButton--secondary appMainButton--cozy" type="button" @click="addNumber('Caf')">
                          <span class=" jaze-icon--plus"></span> <span> Change Caf Number </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxInline noMarginBottom">Select accounts for caf pool</label>
                </div>
                <div class="col--sm7 appFormGroup appFormGroup--cozy">
                  <treeselect :clear-on-select="true" :multiple="true" v-model="selectedAccsCaf" virtual  :virtual-row-height="25" :openOnFocus="true" :options="subAccOptions" :searchable="true" :clearable="false" placeholder="Search" disableFuzzyMatching></treeselect>
                </div>
              </div>
              <fieldset class="appFieldset appFieldset--quiet" v-if="cafData.selectedCafNumber!=undefined && Object.keys(cafData.selectedCafNumber).length != 0">
                <div class="unifiSettingsSection">
                  <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                    <thead>
                      <tr>
                        <th>
                          <span>Caf Number</span>
                        </th>
                        <th>
                          <span>Start Date</span>
                        </th>
                        <th>
                        </th>
                        <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                      </tr>
                    </thead>
                    <tbody class="jazeTableBody">
                      <tr>
                        <td>
                          <span> {{cafData.selectedCafNumber.number}} </span>
                        </td>
                        <td>
                          <span> {{cafData.selectedCafNumber.startDate}} </span>
                        </td>
                        <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                          <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                            <div class="jazeButtonGroup">
                              <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editInvoiceNum('Caf')">
                                <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                              </button>
                              <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteNumber('Caf')">
                                <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table><br><br>
                </div>
              </fieldset>
            </div>
          </div>

        </div>     
        <div class="appFormFooter__right" v-if="selectedSubTab == 'invoicePrefix' && selectedSubTabProforma == 'proformaPrefix' && selectedSubTabPayment == 'paymentPrefix' && selectedSubTabCircuit == 'circuitPrefix' && selectedSubTabLead == 'leadPrefix' && selectedSubTabHelpdesk == 'helpdeskPrefix' && selectedSubTabCreditNote == 'creditNotePrefix' && selectedSubTabCAF == 'cafPrefix' && selectedTab != 'username' && getTheCurrentPrefix.visible == 'visible'">
          <button class="appMainButton busyToggle" type="button" @click="subZoneRestrictions" v-if="getTheCurrentPrefix.edition == 'isp'"> SubZones Restrictions</button>
          <button class="appMainButton busyToggle" type="button" @click="subZoneRestrictions" v-else> SubAccounts Restrictions</button>
          
          <button class="appMainButton appMainButton--primary busyToggle" type="button" @click="pushToSubZones" v-if="getTheCurrentPrefix.edition == 'isp'"> Add to All SubZones</button>
          <button class="appMainButton appMainButton--primary busyToggle" type="button" @click="pushToSubZones" v-else> Add to All SubAccounts</button>
        </div>   
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark" v-if="selectedTab == 'username'">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveUserNamePrefix"> Save</button>
          </div>
        </div>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark" v-else-if="selectedSubTab == 'invoiceNum' || selectedSubTabProforma == 'proformNum' || selectedSubTabPayment == 'paymentNum' || (selectedSubTabCircuit == 'circuitNum' && circuitIdData.sameDb) || selectedSubTabLead == 'leadNum' || selectedSubTabHelpdesk == 'helpdeskNum' || selectedSubTabCreditNote == 'creditNoteNum' || selectedSubTabCAF == 'cafNum'">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="savePrefixSettings(selectedTab)"> Save</button>
          </div>
        </div>           
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="addPrefixModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Add {{prefixType}} Prefix </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="addPrefixModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <form ref="createPrefixForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Prefix for your {{prefixType}}</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <input type="text" class="input--full" name="prefixInvoice" maxlength="100" v-model="prefixInvoice" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Starts From</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <VueCtkDateTimePicker v-model="prefDate" name="prefixStart" :autoClose="true" :noLabel="true" :format="'YYYY-MM-DD'" :formatted="'ll'" :onlyDate="true" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="addPrefixModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="createPrefixConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deletePrefixModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Delete {{prefixType}} prefix </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deletePrefixModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <label class="appLabel appLabel--boxInline">
                        Are you sure want to delete the {{prefixType}} prefix?
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="deletePrefixModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deletePrefixConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="changeInvoiceNumModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Add {{prefixType}} Number </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="changeInvoiceNumModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main" style="overflow:auto;">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <form ref="addInvoiceNumForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">{{prefixType}} start number</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy" v-if="startNumDiv == true">
                              <input type="text" class="input--full" name="numberInvoice" v-model="startNum" />
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy" v-else-if="businessStartNumDiv == true">
                              <input type="text" class="input--full" name="businessStartNum" v-model="businessStartNum" />
                            </div>
                          </div> 
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Starts From</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <VueCtkDateTimePicker v-model="invDate" name="prefixInvoiceStart" :noLabel="true" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="changeInvoiceNumModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addEditInvoiceNum(type)">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteInvoiceModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Delete {{prefixType}} Prefix </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteInvoiceModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <label class="appLabel appLabel--boxInline">
                        Are you sure want to delete the {{prefixType}} number?
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="deleteInvoiceModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deleteNumberConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>    
    <PrefixPushToSubZoneModal @clicked-prefix-push-to-subzone-detail="onclickclose" :prefixTab='prefixTab' :prefixPageData='prefixPageData' :subAccOptions='subAccOptions' :edition='invoiceData.edition' v-if="allSubAccountsModalShow"></PrefixPushToSubZoneModal>
    <PrefixSubZoneRestrictionsModal @clicked-prefix-subzone-restrictions-detail="onclickclose" :prefixTab='prefixTab' :prefixPageData='prefixPageData' v-if="subZoneModalShow"></PrefixSubZoneRestrictionsModal>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import moment from "moment";
import "moment-timezone";
import VueCtkDateTimePicker from "jaze-ctk-date-time-picker";
import PrefixPushToSubZoneModal from "@/components/Settings/PrefixPushToSubZoneModal.vue";
import PrefixSubZoneRestrictionsModal from "@/components/Settings/PrefixSubZoneRestrictionsModal.vue";
import { uselayoutStore } from '@/store/layoutstore'
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  mixins: [overrideIsSelected()],
  setup() {
    const invoiceData = ref( [] )
    const proformaInvoiceData = ref( [] )
    const paymentData = ref( [] )
    const usernameData = ref( [] )
    const circuitIdData = ref( [] )
    const leadData = ref( [] )
    const helpdeskData = ref( [] )
    const creditNoteData = ref( [] )
    const cafData = ref( [] )
    const formClass = ref( "" )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorShow = ref( false )
    const selectedTab = ref( "invoice" )
    const selectedAccsInvoice = ref( new Array("selectAll") )
    const selectedAccsProformaInvoice = ref( new Array("selectAll") )
    const selectedAccsPayment = ref( new Array("selectAll") )
    const selectedAccsCreditNote = ref( new Array("selectAll") )
    const selectedAccsUsername = ref( new Array("selectAll") )
    const selectedAccsUsernameDis=ref(false);
    const selectedAccsCircuitId = ref( new Array("selectAll") )
    const selectedAccsLead = ref( new Array("selectAll") )
    const selectedAccsHelpdesk = ref( new Array("selectAll") )
    const selectedAccsCaf = ref( new Array("selectAll") )
    const selectedAccsBusinessInvoice = ref( new Array("selectAll") )
    const selectedAccsBusinessProformaInvoice = ref( new Array("selectAll") )
    const selectedAccsBusinessPayment = ref( new Array("selectAll") )
    const selectedAccsBusinessCreditNote = ref( new Array("selectAll") )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: []
            }
          ] )
    const selectedSubTab = ref( "invoicePrefix" )
    const selectedSubTabProforma = ref( "proformaPrefix" )
    const selectedSubTabPayment = ref( "paymentPrefix" )
    const selectedSubTabCircuit = ref( "circuitPrefix" )
    const selectedSubTabLead = ref( "leadPrefix" )
    const selectedSubTabHelpdesk = ref( "helpdeskPrefix" )
    const selectedSubTabCreditNote = ref( "creditNotePrefix" )
    const selectedSubTabCAF = ref( "cafPrefix" )
    const addPrefixModal = ref( false )
    const deletePrefixModal = ref( false )
    const changeInvoiceNumModal = ref( false )
    const deleteInvoiceModal = ref( false )
    const prefixToDelete = ref( "" )
    const prefixIdToDelete = ref( "" )
    const prefDate = ref( "" )
    const invDate = ref( "" )
    const keyValue = ref( "" )
    const deleteKeyValue = ref( "" )
    const type = ref( "add" )
    const startNum = ref( "" )
    const businessStartNum = ref("")
    const startNumDiv = ref(false)
    const businessStartNumDiv = ref(false)
    const prefixType = ref( "" )
    const prefixInvoice = ref( "" )
    const usrNamePrefix = ref( "" )
    const automaticCafGeneration  = ref( false )
    const subZoneModalShow = ref( false )
    const allSubAccountsModalShow = ref( false )
    const prefixTab = ref( null )
    const prefixPageData = ref( null )
    const invoiceNumberBasedOnUserType = ref("off")
    const paymentNumberBasedOnUserType = ref("off")
    const proformaInvoiceNumberBasedOnUserType = ref("off")
    const creditNoteNumberBasedOnUserType = ref("off")

    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const settingsStore = usesettingsStore()
    const { 
      invoicePrefixData, 
      proformaInvoicePrefixData, 
      paymentPrefixData, 
      usernamePrefixData, 
      circuitIdPrefixData, 
      leadPrefixData, 
      helpdeskPrefixData, 
      creditNotePrefixData, 
      cafPrefixData 
    } = storeToRefs(settingsStore)
    const invoicePrefixGetter = computed(() => invoicePrefixData.value);
    const proformaInvoiceGetter = computed(() => proformaInvoicePrefixData.value);
    const paymentPrefixGetter = computed(() => paymentPrefixData.value);
    const usernamePrefixGetter = computed(() => usernamePrefixData.value);
    const circuitIdPrefixGetter = computed(() => circuitIdPrefixData.value);
    const leadPrefixGetter = computed(() => leadPrefixData.value);
    const helpdeskPrefixGetter = computed(() => helpdeskPrefixData.value);
    const creditNotePrefixGetter = computed(() => creditNotePrefixData.value);
    const cafPrefixGetter = computed(() => cafPrefixData.value);

    const invoiceDataUpdated = computed(() => {
      if (
        invoiceData.value.prefixIn != undefined &&
        invoiceData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < invoiceData.value.prefixIn.length; i++) {
          var date = moment(
            invoiceData.value.prefixIn[i].InvoicePrefix.start_date
          )
            .tz(invoiceData.value.timezone)
            .format("DD-MM-YYYY");
          invoiceData.value.prefixIn[i].InvoicePrefix.start_date_vue = date;
        }
      }
      return invoiceData.value.prefixIn;
    })
    const businessInvoiceDataUpdated = computed(() => {
      if (
        invoiceData.value.prefixBusinessIn != undefined &&
        invoiceData.value.prefixBusinessIn.length != 0
      ) {
        for (let i = 0; i < invoiceData.value.prefixBusinessIn.length; i++) {
          var date = moment(
            invoiceData.value.prefixBusinessIn[i].InvoicePrefix.start_date
          )
            .tz(invoiceData.value.timezone)
            .format("DD-MM-YYYY");
          invoiceData.value.prefixBusinessIn[i].InvoicePrefix.start_date_vue = date;
        }
      }
      return invoiceData.value.prefixBusinessIn;
    })
    const proformaInvoiceDataUpdated = computed(() => {
      if (
        proformaInvoiceData.value.prefixIn != undefined &&
        proformaInvoiceData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < proformaInvoiceData.value.prefixIn.length; i++) {
          var date = moment(
            proformaInvoiceData.value.prefixIn[i].ProformaInvoicePrefix
              .start_date
          )
            .tz(proformaInvoiceData.value.timezone)
            .format("DD-MM-YYYY");
          proformaInvoiceData.value.prefixIn[
            i
          ].ProformaInvoicePrefix.start_date_vue = date;
        }
      }
      return proformaInvoiceData.value.prefixIn;
    })
     const businessProformaInvoiceDataUpdated = computed(() => {
      if (
        proformaInvoiceData.value.prefixBusinessIn != undefined &&
        proformaInvoiceData.value.prefixBusinessIn.length != 0
      ) {
        for (let i = 0; i < proformaInvoiceData.value.prefixBusinessIn.length; i++) {
          var date = moment(
            proformaInvoiceData.value.prefixBusinessIn[i].ProformaInvoicePrefix
              .start_date
          )
            .tz(proformaInvoiceData.value.timezone)
            .format("DD-MM-YYYY");
          proformaInvoiceData.value.prefixBusinessIn[
            i
          ].ProformaInvoicePrefix.start_date_vue = date;
        }
      }
      return proformaInvoiceData.value.prefixBusinessIn;
    })
    const paymentDataUpdated = computed(() => {
      if (
        paymentData.value.prefixIn != undefined &&
        paymentData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < paymentData.value.prefixIn.length; i++) {
          var date = moment(
            paymentData.value.prefixIn[i].PaymentPrefix.start_date
          )
            .tz(paymentData.value.timezone)
            .format("DD-MM-YYYY");
          paymentData.value.prefixIn[i].PaymentPrefix.start_date_vue = date;
        }
      }
      return paymentData.value.prefixIn;
    })
    const businessPaymentDataUpdated = computed(() => {
      if (
        paymentData.value.prefixBusinessIn != undefined &&
        paymentData.value.prefixBusinessIn.length != 0
      ) {
        for (let i = 0; i < paymentData.value.prefixBusinessIn.length; i++) {
          var date = moment(
            paymentData.value.prefixBusinessIn[i].PaymentPrefix.start_date
          )
            .tz(paymentData.value.timezone)
            .format("DD-MM-YYYY");
          paymentData.value.prefixBusinessIn[i].PaymentPrefix.start_date_vue = date;
        }
      }
      return paymentData.value.prefixBusinessIn;
    })
    const circuitIdDataUpdated = computed(() => {
      if (
        circuitIdData.value.prefixIn != undefined &&
        circuitIdData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < circuitIdData.value.prefixIn.length; i++) {
          var date = moment(
            circuitIdData.value.prefixIn[i].CircuitIdPrefix.start_date
          )
            .tz(circuitIdData.value.timezone)
            .format("DD-MM-YYYY");
          circuitIdData.value.prefixIn[i].CircuitIdPrefix.start_date_vue = date;
        }
      }
      return circuitIdData.value.prefixIn;
    })
    const leadDataUpdated = computed(() => {
      if (
        leadData.value.prefixIn != undefined &&
        leadData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < leadData.value.prefixIn.length; i++) {
          var date = moment(leadData.value.prefixIn[i].LeadPrefix.start_date)
            .tz(leadData.value.timezone)
            .format("DD-MM-YYYY");
          leadData.value.prefixIn[i].LeadPrefix.start_date_vue = date;
        }
      }
      return leadData.value.prefixIn;
    })
    const helpdeskDataUpdated = computed(() => {
      if (
        helpdeskData.value.prefixIn != undefined &&
        helpdeskData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < helpdeskData.value.prefixIn.length; i++) {
          var date = moment(
            helpdeskData.value.prefixIn[i].HelpDeskPrefix.start_date
          )
            .tz(helpdeskData.value.timezone)
            .format("DD-MM-YYYY");
          helpdeskData.value.prefixIn[i].HelpDeskPrefix.start_date_vue = date;
        }
      }
      return helpdeskData.value.prefixIn;
    })
    const creditNoteDataUpdated = computed(() => {
      if (
        creditNoteData.value.prefixIn != undefined &&
        creditNoteData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < creditNoteData.value.prefixIn.length; i++) {
          var date = moment(
            creditNoteData.value.prefixIn[i].CreditNotePrefix.start_date
          )
            .tz(creditNoteData.value.timezone)
            .format("DD-MM-YYYY");
          creditNoteData.value.prefixIn[
            i
          ].CreditNotePrefix.start_date_vue = date;
        }
      }
      return creditNoteData.value.prefixIn;
    })
    const businessCreditNoteDataUpdated = computed(() => {
      if (
        creditNoteData.value.prefixBusinessIn != undefined &&
        creditNoteData.value.prefixBusinessIn.length != 0
      ) {
        for (let i = 0; i < creditNoteData.value.prefixBusinessIn.length; i++) {
          var date = moment(
            creditNoteData.value.prefixBusinessIn[i].CreditNotePrefix.start_date
          )
            .tz(creditNoteData.value.timezone)
            .format("DD-MM-YYYY");
          creditNoteData.value.prefixBusinessIn[
            i
          ].CreditNotePrefix.start_date_vue = date;
        }
      }
      return creditNoteData.value.prefixBusinessIn;
    })
    const cafDataUpdated = computed(() => {
      if (
        cafData.value.prefixIn != undefined &&
        cafData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < cafData.value.prefixIn.length; i++) {
          var date = moment(cafData.value.prefixIn[i].CafPrefix.start_date)
            .tz(cafData.value.timezone)
            .format("DD-MM-YYYY");
          cafData.value.prefixIn[i].CafPrefix.start_date_vue = date;
        }
      }
      return cafData.value.prefixIn;
    })
    const getTheCurrentPrefix = computed(() => {
      if(selectedSubTab.value == 'invoicePrefix' && selectedTab.value == 'invoice') {
        prefixTab.value = 'invoicePrefix';     
        prefixPageData.value = invoiceData.value;   
      } else if(selectedSubTabProforma.value == 'proformaPrefix' && selectedTab.value == 'proformaInvoice') {
        prefixTab.value = 'proformaPrefix';
        prefixPageData.value = proformaInvoiceData.value;   
      } else if(selectedSubTabPayment.value == 'paymentPrefix' && selectedTab.value == 'payment') {
        prefixTab.value = 'paymentPrefix';
        prefixPageData.value = paymentData.value;   
      } else if(selectedSubTabCircuit.value == 'circuitPrefix' && selectedTab.value == 'circuitId') {
        prefixTab.value = 'circuitPrefix';
        prefixPageData.value = circuitIdData.value;   
      } else if(selectedSubTabLead.value == 'leadPrefix' && selectedTab.value == 'lead') {
        prefixTab.value = 'leadPrefix';
        prefixPageData.value = leadData.value;   
      } else if(selectedSubTabHelpdesk.value == 'helpdeskPrefix' && selectedTab.value == 'helpdesk') {
        prefixTab.value = 'helpdeskPrefix';
        prefixPageData.value = helpdeskData.value;   
      } else if(selectedSubTabCreditNote.value == 'creditNotePrefix' && selectedTab.value == 'creditNote') {
        prefixTab.value = 'creditNotePrefix';
        prefixPageData.value = creditNoteData.value;   
      } else if(selectedSubTabCAF.value == 'cafPrefix' && selectedTab.value == 'caf') {
        prefixTab.value = 'cafPrefix';
        prefixPageData.value = cafData.value;   
      }  
      return prefixPageData.value;
    })

    onMounted(() => {
      getPrefixData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      if (
        subaccDetailsGetter.value != undefined &&
        subaccDetailsGetter.value.length != 0
      ) {
        for (let i = 0; i < subaccDetailsGetter.value.length-1; i++) {
          subAccOptions.value[0].children.push({
            id: subaccDetailsGetter.value[i].id,
            label: subaccDetailsGetter.value[i].name
          });
        }
      }
    })

    watch(invoicePrefixGetter,() => {
      invoiceData.value = invoicePrefixGetter.value;

      if (
        invoiceData.value != undefined &&
        invoiceData.value.permissions != undefined &&
        invoiceData.value.permissions.Billing == true
      ) {
        selectedTab.value = "invoice";
      } else {
        selectedTab.value = "username";
      }      
      selectedAccsInvoice.value = new Array();
      if (
        invoiceData.value.selectedAccounts != undefined &&
        invoiceData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < invoiceData.value.selectedAccounts.length; i++) {
          selectedAccsInvoice.value.push(invoiceData.value.selectedAccounts[i]);
        }
      }
      selectedAccsBusinessInvoice.value = new Array();
      if (
        invoiceData.value.selectedBusinessAccounts != undefined &&
        invoiceData.value.selectedBusinessAccounts.length != 0
      ) {
        for (let i = 0; i < invoiceData.value.selectedBusinessAccounts.length; i++) {
          selectedAccsBusinessInvoice.value.push(invoiceData.value.selectedBusinessAccounts[i]);
        }
      }
      if (
        Object.keys(invoiceData.value).length != 0 &&
        Object.keys(invoiceData.value.selectedInvoiceNumber).length != 0
      ) {
        invDate.value = moment(
          invoiceData.value.selectedInvoiceNumber.startDate
        ).format("YYYY-MM-DD H:mm:ss");
      }  
      if (
        invoiceData.value != undefined &&
        invoiceData.value.invoiceNumTypeValue != undefined &&
        invoiceData.value.invoiceNumTypeValue == "yes"
      ) {
        invoiceNumberBasedOnUserType.value = "on";
      } else {
        invoiceNumberBasedOnUserType.value = "off";
      }
    })
    watch(proformaInvoiceGetter,() => {
      proformaInvoiceData.value = proformaInvoiceGetter.value;
      selectedAccsProformaInvoice.value = new Array();
      if (
        proformaInvoiceData.value.selectedAccounts != undefined &&
        proformaInvoiceData.value.selectedAccounts.length != 0
      ) {
        for (
          let i = 0;
          i < proformaInvoiceData.value.selectedAccounts.length;
          i++
        ) {
          selectedAccsProformaInvoice.value.push(
            proformaInvoiceData.value.selectedAccounts[i]
          );
        }
      }
      selectedAccsBusinessProformaInvoice.value = new Array();
      if (
        proformaInvoiceData.value.selectedBusinessAccounts != undefined &&
        proformaInvoiceData.value.selectedBusinessAccounts.length != 0
      ) {
        for (
          let i = 0;
          i < proformaInvoiceData.value.selectedBusinessAccounts.length;
          i++
        ) {
          selectedAccsBusinessProformaInvoice.value.push(
            proformaInvoiceData.value.selectedBusinessAccounts[i]
          );
        }
      }
      if (
        Object.keys(proformaInvoiceData.value).length != 0 &&
        Object.keys(proformaInvoiceData.value.selectedProformaNumber).length != 0
      ) {
        invDate.value = moment(
          proformaInvoiceData.value.selectedProformaNumber.startDate
        ).format("YYYY-MM-DD H:mm:ss");
      }
      if (
        proformaInvoiceData.value != undefined &&
        proformaInvoiceData.value.proformaInvoiceNumTypeValue != undefined &&
        proformaInvoiceData.value.proformaInvoiceNumTypeValue == "yes"
      ) {
        proformaInvoiceNumberBasedOnUserType.value = "on";
      } else {
        proformaInvoiceNumberBasedOnUserType.value = "off";
      }
    })
    watch(paymentPrefixGetter,() => {
      paymentData.value = paymentPrefixGetter.value;
      if (
        paymentData.value.prefixIn != undefined &&
        paymentData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < paymentData.value.prefixIn.length; i++) {
          var date = moment(
            paymentData.value.prefixIn[i].PaymentPrefix.start_date
          )
            .tz(paymentData.value.timezone)
            .format("DD-MM-YYYY");
          paymentData.value.prefixIn[i].PaymentPrefix.start_date_vue = date;
        }
      }
      selectedAccsPayment.value = new Array();
      if (
        paymentData.value.selectedAccounts != undefined &&
        paymentData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < paymentData.value.selectedAccounts.length; i++) {
          selectedAccsPayment.value.push(paymentData.value.selectedAccounts[i]);
        }
      }
      selectedAccsBusinessPayment.value = new Array();
      if (
        paymentData.value.selectedBusinessAccounts != undefined &&
        paymentData.value.selectedBusinessAccounts.length != 0
      ) {
        for (let i = 0; i < paymentData.value.selectedBusinessAccounts.length; i++) {
          selectedAccsBusinessPayment.value.push(paymentData.value.selectedBusinessAccounts[i]);
        }
      }
      if (
        paymentData.value != undefined &&
        paymentData.value.paymentNumTypeValue != undefined &&
        paymentData.value.paymentNumTypeValue == "yes"
      ) {
        paymentNumberBasedOnUserType.value = "on";
      } else {
        paymentNumberBasedOnUserType.value = "off";
      }
    })
    watch(usernamePrefixGetter,() => {
      usernameData.value = usernamePrefixGetter.value;
      usrNamePrefix.value = usernameData.value.userNamePrefix;
      selectedAccsUsernameDis.value=false;
      selectedAccsUsername.value = new Array();
      if (
        usernameData.value.selectedAccounts != undefined &&
        usernameData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < usernameData.value.selectedAccounts.length; i++) {
          selectedAccsUsername.value.push(usernameData.value.selectedAccounts[i]);
        }
      }
      var uniqueItems = Array.from(new Set(selectedAccsUsername.value));
      selectedAccsUsername.value = uniqueItems;
      setTimeout(
        function() {
            selectedAccsUsernameDis.value=true;
        }.bind(this),
         0.1
      );
     
    })
    watch(circuitIdPrefixGetter,() => {
      circuitIdData.value = circuitIdPrefixGetter.value;
      selectedAccsCircuitId.value = new Array();
      if (
        circuitIdData.value.selectedAccounts != undefined &&
        circuitIdData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < circuitIdData.value.selectedAccounts.length; i++) {
          selectedAccsCircuitId.value.push(
            circuitIdData.value.selectedAccounts[i]
          );
        }
      }
    })
    watch(leadPrefixGetter,() => {
      leadData.value = leadPrefixGetter.value;
      selectedAccsLead.value = new Array();
      if (
        leadData.value.selectedAccounts != undefined &&
        leadData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < leadData.value.selectedAccounts.length; i++) {
          selectedAccsLead.value.push(leadData.value.selectedAccounts[i]);
        }
      }
    })
    watch(helpdeskPrefixGetter,() => {
      helpdeskData.value = helpdeskPrefixGetter.value;
      selectedAccsHelpdesk.value = new Array();
      if (
        helpdeskData.value.selectedAccounts != undefined &&
        helpdeskData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < helpdeskData.value.selectedAccounts.length; i++) {
          selectedAccsHelpdesk.value.push(helpdeskData.value.selectedAccounts[i]);
        }
      }
    })
    watch(creditNotePrefixGetter,() => {
      creditNoteData.value = creditNotePrefixGetter.value;
      if (
        creditNoteData.value.prefixIn != undefined &&
        creditNoteData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < creditNoteData.value.prefixIn.length; i++) {
          var date = moment(
            creditNoteData.value.prefixIn[i].CreditNotePrefix.start_date
          )
            .tz(creditNoteData.value.timezone)
            .format("DD-MM-YYYY");
          creditNoteData.value.prefixIn[
            i
          ].CreditNotePrefix.start_date_vue = date;
        }
      }
      selectedAccsCreditNote.value = new Array();
      if (
        creditNoteData.value.selectedAccounts != undefined &&
        creditNoteData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < creditNoteData.value.selectedAccounts.length; i++) {
          selectedAccsCreditNote.value.push(
            creditNoteData.value.selectedAccounts[i]
          );
        }
      }
      selectedAccsBusinessCreditNote.value = new Array();
      if (
        creditNoteData.value.selectedBusinessAccounts != undefined &&
        creditNoteData.value.selectedBusinessAccounts.length != 0
      ) {
        for (let i = 0; i < creditNoteData.value.selectedBusinessAccounts.length; i++) {
          selectedAccsBusinessCreditNote.value.push(
            creditNoteData.value.selectedBusinessAccounts[i]
          );
        }
      }
      
      if (
        creditNoteData.value != undefined &&
        creditNoteData.value.creditNoteNumTypeValue != undefined &&
        creditNoteData.value.creditNoteNumTypeValue == "yes"
      ) {
        creditNoteNumberBasedOnUserType.value = "on";
      } else {
        creditNoteNumberBasedOnUserType.value = "off";
      }
    })
    watch(cafPrefixGetter,() => {
      cafData.value = cafPrefixGetter.value;
      if (
        cafData.value.prefixIn != undefined &&
        cafData.value.prefixIn.length != 0
      ) {
        for (let i = 0; i < cafData.value.prefixIn.length; i++) {
          var date = moment(cafData.value.prefixIn[i].CafPrefix.start_date)
            .tz(cafData.value.timezone)
            .format("DD-MM-YYYY");
          cafData.value.prefixIn[i].CafPrefix.start_date_vue = date;
        }
      }
      selectedAccsCaf.value = new Array();
      if (
        cafData.value.selectedAccounts != undefined &&
        cafData.value.selectedAccounts.length != 0
      ) {
        for (let i = 0; i < cafData.value.selectedAccounts.length; i++) {
          selectedAccsCaf.value.push(
            cafData.value.selectedAccounts[i]
          );
        }
      }
      automaticCafGeneration.value = cafData.value.automaticCafGeneration;
      // console.log("cafData.value",cafData.value);
    })

    const getPrefixData = () => {
      var day = moment(new Date());
      prefDate.value = moment(day).format("YYYY-MM-DD");
      invDate.value = moment(day).format("YYYY-MM-DD H:mm:ss");
      // $store.dispatch("fetchinvoicePrefixData");
      settingsStore.fetchinvoicePrefixData();
    }
    const switchTabs = (tab) => {
      selectedTab.value = tab;
      if (tab == "proformaInvoice") {
        if (Object.keys(proformaInvoiceGetter.value).length == 0) {
          //$store.dispatch("fetchProformaInvoiceData");
          settingsStore.fetchProformaInvoiceData();
        } else {
          proformaInvoiceData.value = proformaInvoiceGetter.value;
        }
      } else if (tab == "payment") {
        if (Object.keys(paymentPrefixGetter.value).length == 0) {
          // $store.dispatch("fetchPaymentPrefixData");
          settingsStore.fetchPaymentPrefixData();
        } else {
          paymentData.value = paymentPrefixGetter.value;
        }
      } else if (tab == "username") {
        if (Object.keys(usernamePrefixGetter.value).length == 0) {
          // $store.dispatch("fetchUsernamePrefixData");
          settingsStore.fetchUsernamePrefixData();
        } else {
          usernameData.value = usernamePrefixGetter.value;
        }
      } else if (tab == "circuitId") {
        if (Object.keys(circuitIdPrefixGetter.value).length == 0) {
          // $store.dispatch("fetchCircuitIdPrefixData");
          settingsStore.fetchCircuitIdPrefixData();
        } else {
          circuitIdData.value = circuitIdPrefixGetter.value;
        }
      } else if (tab == "lead") {
        if (Object.keys(leadPrefixGetter.value).length == 0) {
          // $store.dispatch("fetchLeadPrefixData");
          settingsStore.fetchLeadPrefixData();
        } else {
          leadData.value = leadPrefixGetter.value;
        }
      } else if (tab == "helpdesk") {
        if (Object.keys(helpdeskPrefixGetter.value).length == 0) {
          // $store.dispatch("fetchHelpdeskPrefixData");
          settingsStore.fetchHelpdeskPrefixData();
        } else {
          helpdeskData.value = helpdeskPrefixGetter.value;
        }
      } else if (tab == "creditNote") {
        if (Object.keys(creditNotePrefixGetter.value).length == 0) {
          // $store.dispatch("fetchCreditNotePrefixData");
          settingsStore.fetchCreditNotePrefixData();
        } else {
          creditNoteData.value = creditNotePrefixGetter.value;
        }
      } else if (tab == "caf") {
        if (Object.keys(cafPrefixGetter.value).length == 0) {
          // $store.dispatch("fetchCafPrefixData");
          settingsStore.fetchCafPrefixData();
        } else {
          cafData.value = cafPrefixGetter.value;
        }
      }
    }
    const addPrefix = (tab) => {
      prefixType.value = tab;
      prefixInvoice.value = "";
      var day = moment(new Date());
      prefDate.value = moment(day).format("YYYY-MM-DD");
      addPrefixModal.value = true;
    }
    const deleteNumber = (tab, key = "") => {
      prefixType.value = tab;
      deleteKeyValue.value = key;
      deleteInvoiceModal.value = true;
    }
    const addNumber = (tab) => {
      prefixType.value = tab;
      startNum.value = "";
      businessStartNum.value = "";
      type.value = "add";
      keyValue.value = "";
      var day = moment(new Date());
      invDate.value = moment(day).format("YYYY-MM-DD H:mm:ss");
      if(prefixType.value.includes('Business')) {
        businessStartNumDiv.value = true;
        startNumDiv.value = false;
      } else {
        businessStartNumDiv.value = false;
        startNumDiv.value = true;
      }
      changeInvoiceNumModal.value = true;
    }
    const deletePrefix = (prefix, tab) => {
      prefixType.value = tab;
      if (tab == "Invoice" || tab == "BusinessInvoice" ) {
        prefixToDelete.value = prefix.InvoicePrefix.prefix;
        prefixIdToDelete.value = prefix.InvoicePrefix.start_date;
      } else if (tab == "Proforma Invoice" || tab == "BusinessProformaInvoice") {
        prefixToDelete.value = prefix.ProformaInvoicePrefix.prefix;
        prefixIdToDelete.value = prefix.ProformaInvoicePrefix.start_date;
      } else if (tab == "Payment" || tab == "BusinessPayment") {
        prefixToDelete.value = prefix.PaymentPrefix.prefix;
        prefixIdToDelete.value = prefix.PaymentPrefix.start_date;
      } else if (tab == "Circuit Id") {
        prefixToDelete.value = prefix.CircuitIdPrefix.prefix;
        prefixIdToDelete.value = prefix.CircuitIdPrefix.start_date;
      } else if (tab == "Lead") {
        prefixToDelete.value = prefix.LeadPrefix.prefix;
        prefixIdToDelete.value = prefix.LeadPrefix.start_date;
      } else if (tab == "HelpDesk") {
        prefixToDelete.value = prefix.HelpDeskPrefix.prefix;
        prefixIdToDelete.value = prefix.HelpDeskPrefix.start_date;
      } else if (tab == "CreditNote"|| tab == "BusinessCreditNote") {
        prefixToDelete.value = prefix.CreditNotePrefix.prefix;
        prefixIdToDelete.value = prefix.CreditNotePrefix.start_date;
      } else if (tab == "CAF") {
        prefixToDelete.value = prefix.CafPrefix.prefix;
        prefixIdToDelete.value = prefix.CafPrefix.start_date;
      }
      deletePrefixModal.value = true;
    }
    const deleteNumberConfirm = () => {
      var funcName = "";
      var prefix = "";
      var prefixId = "";
      var key = "";
      if (selectedTab.value == "invoice") {
        funcName = "deleteNumforInvoice";
        if(prefixType.value == 'BusinessInvoice') {
          prefix = invoiceData.value.selectedBusinessInvoiceNumber.number;
          prefixId = invoiceData.value.selectedBusinessInvoiceNumber.startDate;
        } else {
          prefix = invoiceData.value.selectedInvoiceNumber.number;
          prefixId = invoiceData.value.selectedInvoiceNumber.startDate;
        }        
        key = deleteKeyValue.value;
      } else if (selectedTab.value == "proformaInvoice") {
        funcName = "deleteNumforProformaInvoice";
        if(prefixType.value == 'BusinessProformaInvoice') {
          prefix = proformaInvoiceData.value.selectedBusinessProformaInvoiceNumber.number;
          prefixId = proformaInvoiceData.value.selectedBusinessProformaInvoiceNumber.startDate;
        } else {
          prefix = proformaInvoiceData.value.selectedProformaNumber.number;
          prefixId = proformaInvoiceData.value.selectedProformaNumber.startDate;
        }
      } else if (selectedTab.value == "payment") {
        funcName = "deleteNumforPayment";
        if(prefixType.value == 'BusinessPayment') {
          prefix = paymentData.value.selectedBusinessPaymentNumber.number;
          prefixId = paymentData.value.selectedBusinessPaymentNumber.startDate;
        } else {
          prefix = paymentData.value.selectedPaymentNumber.number;
          prefixId = paymentData.value.selectedPaymentNumber.startDate;
        }
      } else if (selectedTab.value == "circuitId") {
        funcName = "deleteNumforCircuit";
        prefix = circuitIdData.value.selectedCircuitNumber.number;
        prefixId = circuitIdData.value.selectedCircuitNumber.startDate;
      } else if (selectedTab.value == "lead") {
        funcName = "deleteNumforLead";
        prefix = leadData.value.selectedLeadNumber.number;
        prefixId = leadData.value.selectedLeadNumber.startDate;
      } else if (selectedTab.value == "helpdesk") {
        funcName = "deleteNumforHelpdesk";
        prefix = helpdeskData.value.selectedHelpDeskNumber.number;
        prefixId = helpdeskData.value.selectedHelpDeskNumber.startDate;
      } else if (selectedTab.value == "creditNote") {
        funcName = "deleteNumforCreditNote";
        if(prefixType.value == 'BusinessCreditNote') {
          prefix = creditNoteData.value.selectedBusinessCreditNoteNumber.number;
          prefixId = creditNoteData.value.selectedBusinessCreditNoteNumber.startDate;
        } else {
          prefix = creditNoteData.value.selectedPaymentNumber.number;
          prefixId = creditNoteData.value.selectedPaymentNumber.startDate;
        }        
      } else if (selectedTab.value == "caf") {
        funcName = "deleteNumforCaf";
        prefix = cafData.value.selectedCafNumber.number;
        prefixId = cafData.value.selectedCafNumber.startDate;
      }
      var data = {
        prefix: prefix,
        prefixId: prefixId,
        key: key,
        funcName: funcName
      };
      if(prefixType.value == 'BusinessInvoice') {
          data.businessInvoiceNumber = "yes";
      } else {
        data.businessInvoiceNumber = "no";
      }
      if(prefixType.value == 'BusinessPayment') {
          data.businessPaymentNumber = "yes";
      } else {
        data.businessPaymentNumber = "no";
      }
      if(prefixType.value == 'BusinessProformaInvoice') {
          data.businessProformaInvoiceNumber = "yes";
      } else {
        data.businessProformaInvoiceNumber = "no";
      }
      if(prefixType.value == 'BusinessCreditNote') {
          data.businessCreditNoteNumber = "yes";
      } else {
        data.businessCreditNoteNumber = "no";
      }
      //$store.dispatch(funcName, data).then(
      settingsStore.deleteNumberConfirm(data).then(
        response => {
          deleteInvoiceModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          deleteInvoiceModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
        }
      );
    }
    const createPrefixConfirm = () => {
      errorShow.value = false;
      var funcName = "";
      var postData = {};
      if (selectedTab.value == "invoice" || selectedTab.value == "BusinessInvoice") {
        funcName = "createPrefixforInvoice";
        postData.prefixInvoice = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
      } else if (selectedTab.value == "proformaInvoice" || selectedTab.value == "BusinessProformaInvoice") {
        funcName = "createPrefixforProformaInvoice";
        postData.prefixInvoice = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
      } else if (selectedTab.value == "payment" || selectedTab.value == "BusinessPayment") {
        postData.prefixPayment = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
        funcName = "createPrefixforPayment";
      } else if (selectedTab.value == "circuitId") {
        postData.circuitIdPrefix = prefixInvoice.value;
        postData.circuitIdPrefixStart = prefDate.value;
        funcName = "createPrefixforCircuit";
      } else if (selectedTab.value == "lead") {
        postData.prefixLead = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
        funcName = "createPrefixforLead";
      } else if (selectedTab.value == "helpdesk") {
        postData.prefixHelpDesk = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
        funcName = "createPrefixforHelpdesk";
      } else if (selectedTab.value == "creditNote" || selectedTab.value == "BusinessCreditNote") {
        postData.prefixCreditNote = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
        funcName = "createPrefixforCreditNote";
      } else if (selectedTab.value == "caf") {
        postData.prefixCaf = prefixInvoice.value;
        postData.prefixStart = prefDate.value;
        funcName = "createPrefixforCaf";
      }
      postData.prefixType = prefixType.value;
      postData.funcName = funcName;
      // $store.dispatch(funcName, postData).then(
      settingsStore.createPrefixConfirm(postData).then(  
        response => {
          addPrefixModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errorShow.value = true;
          errorMessage.value = error;
          return;
        }
      );
    }
    const deletePrefixConfirm = () => {
      
      var funcName = "";
      if (selectedTab.value == "invoice") {
        funcName = "deletePrefixforInvoice";
      } else if (selectedTab.value == "proformaInvoice") {
        funcName = "deletePrefixforProformaInvoice";
      } else if (selectedTab.value == "payment") {
        funcName = "deletePrefixforPayment";
      } else if (selectedTab.value == "circuitId") {
        funcName = "deletePrefixforCircuit";
      } else if (selectedTab.value == "lead") {
        funcName = "deletePrefixforLead";
      } else if (selectedTab.value == "helpdesk") {
        funcName = "deletePrefixforHelpdesk";
      } else if (selectedTab.value == "creditNote") {
        funcName = "deletePrefixforCreditNote";
      } else if (selectedTab.value == "caf") {
        funcName = "deletePrefixforCaf";
      }
      var data = {
        prefix: prefixToDelete.value,
        prefixId: prefixIdToDelete.value,
        funcName: funcName,
        prefixType:prefixType.value
      };
      // $store.dispatch(funcName, data).then(
      settingsStore.deletePrefixConfirm(data).then(  
        response => {
          deletePrefixModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          deletePrefixModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
        }
      );
    }
    const editInvoiceNum = (tab, key = "") => {
      type.value = "edit";
      prefixType.value = tab;
      if (selectedTab.value == "invoice") {
        if(prefixType.value == "BusinessInvoice") {
          businessStartNum.value = invoiceData.value.selectedBusinessInvoiceNumber[key].number;
          invDate.value = invoiceData.value.selectedBusinessInvoiceNumber[key].startDate;
        } else {
          startNum.value = invoiceData.value.selectedInvoiceNumber[key].number;
          invDate.value = invoiceData.value.selectedInvoiceNumber[key].startDate;
        }
        keyValue.value = key;
      } else if (selectedTab.value == "proformaInvoice") {
        if(prefixType.value == "BusinessProformaInvoice") {
          businessStartNum.value = proformaInvoiceData.value.selectedBusinessProformaInvoiceNumber.number;
          invDate.value = proformaInvoiceData.value.selectedBusinessProformaInvoiceNumber.startDate;
        } else {
          startNum.value = proformaInvoiceData.value.selectedProformaNumber.number;
          invDate.value = proformaInvoiceData.value.selectedProformaNumber.startDate; 
        }
      } else if (selectedTab.value == "payment") {
        if(prefixType.value == "BusinessPayment") {
          businessStartNum.value = paymentData.value.selectedBusinessPaymentNumber.number;
          invDate.value = paymentData.value.selectedBusinessPaymentNumber.startDate;
        } else {
          startNum.value = paymentData.value.selectedPaymentNumber.number;
          invDate.value = paymentData.value.selectedPaymentNumber.startDate;
        }        
      } else if (selectedTab.value == "circuitId") {
        startNum.value = circuitIdData.value.selectedCircuitNumber.number;
        invDate.value = circuitIdData.value.selectedCircuitNumber.startDate;
      } else if (selectedTab.value == "lead") {
        startNum.value = leadData.value.selectedLeadNumber.number;
        invDate.value = leadData.value.selectedLeadNumber.startDate;
      } else if (selectedTab.value == "helpdesk") {
        startNum.value = helpdeskData.value.selectedHelpDeskNumber.number;
        invDate.value = helpdeskData.value.selectedHelpDeskNumber.startDate;
      } else if (selectedTab.value == "creditNote") {
        if(prefixType.value == "BusinessCreditNote") {
          businessStartNum.value = creditNoteData.value.selectedBusinessCreditNoteNumber.number;
          invDate.value = creditNoteData.value.selectedBusinessCreditNoteNumber.startDate;
        } else {
          startNum.value = creditNoteData.value.selectedPaymentNumber.number;
          invDate.value = creditNoteData.value.selectedPaymentNumber.startDate;
        }
      } else if (selectedTab.value == "caf") {
        startNum.value = cafData.value.selectedCafNumber.number;
        invDate.value = cafData.value.selectedCafNumber.startDate;
      }
      changeInvoiceNumModal.value = true;
    }
    const checkPrefixNumberType = (number) => {
      if (!Number.isInteger(parseInt(number))) {
        return "error";
      }
    }
    const addEditInvoiceNum = (type) => {
      var funcName = "";
      var postData = {};
      if(selectedTab.value == "invoice") {
        postData.businessInvoice = "no";
        if(prefixType.value == "Invoice") {
          const status = checkPrefixNumberType(startNum.value);
          if (status == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          }        
        } else if(prefixType.value == "BusinessInvoice") {
          const status1 = checkPrefixNumberType(businessStartNum.value);
          if (status1 == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          } else {
            postData.businessInvoice = "yes";
          }
        }
      } else if(selectedTab.value == "payment") {
        postData.businessPayment = "no";
        if(prefixType.value == "Payment") {
          const status = checkPrefixNumberType(startNum.value);
          if (status == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          }        
        } else if(prefixType.value == "BusinessPayment") {
          const status1 = checkPrefixNumberType(businessStartNum.value);
          if (status1 == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          } else {
            postData.businessPayment = "yes";
          }
        }
      } else if(selectedTab.value == "proformaInvoice") {
        postData.businessProformaInvoice = "no";
        if(prefixType.value == "Proforma Invoice") {
          const status = checkPrefixNumberType(startNum.value);
          if (status == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          }        
        } else if(prefixType.value == "BusinessProformaInvoice") {
          const status1 = checkPrefixNumberType(businessStartNum.value);
          if (status1 == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          } else {
            postData.businessProformaInvoice = "yes";
          }
        }
      } else if(selectedTab.value == "creditNote") {
        postData.businessCreditNote = "no";
        if(prefixType.value == "CreditNote") {
          const status = checkPrefixNumberType(startNum.value);
          if (status == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          }        
        } else if(prefixType.value == "BusinessCreditNote") {
          const status1 = checkPrefixNumberType(businessStartNum.value);
          if (status1 == "error") {
            errorShow.value = true;
            errorMessage.value = "Alphabet not allowed.Please Enter Number";
            setTimeout(() => {
              errorShow.value = false;
            }, 6000);
            return;
          } else {
            postData.businessCreditNote = "yes";
          }
        }
      } 
      postData.numbermethod = type;
      if (selectedTab.value == "invoice") {
        funcName = "createInvoiceNumber";
        postData.numberInvoice = startNum.value;
        postData.businessNumberInvoice = businessStartNum.value;
        postData.prefixInvoiceStart = invDate.value;
        postData.key = keyValue.value;
      } else if (selectedTab.value == "proformaInvoice") {
        funcName = "createProformaInvoiceNumber";
        postData.numberProforma = startNum.value;
        postData.businessNumberProforma = businessStartNum.value;
        postData.prefixProformaStart = invDate.value;
      } else if (selectedTab.value == "payment") {
        funcName = "createPaymentNumber";
        postData.numberPayment = startNum.value;
        postData.businessNumberPayment = businessStartNum.value;
        postData.prefixPaymentStart = invDate.value;
      } else if (selectedTab.value == "circuitId") {
        funcName = "createCircuitIdNumber";
        postData.numberCircuit = startNum.value;
        postData.prefixCircuitStart = invDate.value;
      } else if (selectedTab.value == "lead") {
        funcName = "createLeadNumber";
        postData.numberLead = startNum.value;
        postData.prefixLeadStart = invDate.value;
      } else if (selectedTab.value == "helpdesk") {
        funcName = "createHelpdeskNumber";
        postData.numberHelpDesk = startNum.value;
        postData.prefixHelpDeskStart = invDate.value;
      } else if (selectedTab.value == "creditNote") {
        funcName = "createCreditNoteNumber";
        postData.numberCreditNote = startNum.value;
        postData.businessNumberCreditNote = businessStartNum.value;
        postData.prefixCreditNoteStart = invDate.value;
      } else if (selectedTab.value == "caf") {
        funcName = "createCafNumber";
        postData.numberCaf = startNum.value;
        postData.prefixCafStart = invDate.value;
      }
      postData.funcName = funcName
      // $store.dispatch(funcName, postData).then(
      settingsStore.addEditInvoiceNum(postData).then(  
        response => {
          changeInvoiceNumModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errorShow.value = true;
          errorMessage.value = error;
          return;
        }
      );
    }
    const savePrefixSettings = (selected) => {
      successtoaster.value = false;
      errortoaster.value = false;
      var funcName = "";
      var accountId = [];
      var businessAccountId = [];
      if (selected == "invoice") {
        funcName = "invoicePrefixSettingsSubmit";
        if (
          selectedAccsInvoice.value.length == 1 &&
          selectedAccsInvoice.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsInvoice.value;
        }
        if (
          selectedAccsBusinessInvoice.value.length == 1 &&
          selectedAccsBusinessInvoice.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            businessAccountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          businessAccountId = selectedAccsBusinessInvoice.value;
        }
      } else if (selected == "proformaInvoice") {
        funcName = "invoicePrefixSettingsSubmit";
        if (
          selectedAccsProformaInvoice.value.length == 1 &&
          selectedAccsProformaInvoice.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsProformaInvoice.value;
        }

        if (
          selectedAccsBusinessProformaInvoice.value.length == 1 &&
          selectedAccsBusinessProformaInvoice.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            businessAccountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          businessAccountId = selectedAccsBusinessProformaInvoice.value;
        }
      } else if (selected == "payment") {
        funcName = "paymentPrefixSettingsSubmit";
        if (
          selectedAccsPayment.value.length == 1 &&
          selectedAccsPayment.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsPayment.value;
        }
        if (
          selectedAccsBusinessPayment.value.length == 1 &&
          selectedAccsBusinessPayment.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            businessAccountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          businessAccountId = selectedAccsBusinessPayment.value;
        }
      } else if (selected == "circuitId") {
        funcName = "circuitPrefixSettingsSubmit";
        if (
          selectedAccsCircuitId.value.length == 1 &&
          selectedAccsCircuitId.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsCircuitId.value;
        }
      } else if (selected == "lead") {
        funcName = "leadPrefixSettingsSubmit";
        if (
          selectedAccsLead.value.length == 1 &&
          selectedAccsLead.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsLead.value;
        }
      } else if (selected == "helpdesk") {
        funcName = "helpdeskPrefixSettingsSubmit";
        if (
          selectedAccsHelpdesk.value.length == 1 &&
          selectedAccsHelpdesk.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsHelpdesk.value;
        }
      } else if (selected == "creditNote") {
        funcName = "creditNotePrefixSettingsSubmit";
        if (
          selectedAccsCreditNote.value.length == 1 &&
          selectedAccsCreditNote.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsCreditNote.value;
        }

        if (
          selectedAccsBusinessCreditNote.value.length == 1 &&
          selectedAccsBusinessCreditNote.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            businessAccountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          businessAccountId = selectedAccsBusinessCreditNote.value;
        }
      } else if (selected == "caf") {
        funcName = "cafPrefixSettingsSubmit";
        if (
          selectedAccsCaf.value.length == 1 &&
          selectedAccsCaf.value[0] == "selectAll"
        ) {
          for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
            accountId.push(subAccOptions.value[0].children[i].id);
          }
        } else {
          accountId = selectedAccsCaf.value;
        }
      }
      if (selected == "circuitId") {
        var formData = {
          accountIdCircuitId: accountId
        };
      } else {
        var formData = {
          accountId: accountId,
          businessAccountId:businessAccountId
        };
      }
      formData.funcName = funcName
      formData.invoiceNumberBasedOnUserType = invoiceNumberBasedOnUserType.value == "on" ? "yes" : "no"
      formData.paymentNumberBasedOnUserType = paymentNumberBasedOnUserType.value == "on" ? "yes" : "no"
      formData.proformaInvoiceNumberBasedOnUserType = proformaInvoiceNumberBasedOnUserType.value == "on" ? "yes" : "no"
      formData.creditNoteNumberBasedOnUserType = creditNoteNumberBasedOnUserType.value == "on" ? "yes" : "no"
      // $store.dispatch(funcName, formData).then(
      settingsStore.savePrefixSettings(formData).then(
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
          if(selected == "invoice") {
            settingsStore.fetchinvoicePrefixData();
          } else if(selected == "payment") {
            settingsStore.fetchPaymentPrefixData();
          } else if(selected == "proformaInvoice") {
            settingsStore.fetchProformaInvoiceData();
          } else if(selected == "creditNote") {
            settingsStore.fetchCreditNotePrefixData();
          }
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
          console.log(error);
        }
      );
    }
    const saveUserNamePrefix = () => {
      var accountId = [];
      if (
        selectedAccsUsername.value.length == 1 &&
        selectedAccsUsername.value[0] == "selectAll"
      ) {
        for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
          accountId.push(subAccOptions.value[0].children[i].id);
        }
      } else {
        accountId = selectedAccsUsername.value;
      }
      var data = {
        userNamePrefix: usrNamePrefix.value,
        accountId: accountId
      };
      // $store.dispatch("usernamePrefixSUbmit", data).then(
      settingsStore.usernamePrefixSUbmit(data).then(
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
          console.log(error);
        }
      );
    }
    const updateAutoCaf = () => {
      // console.log("automaticCafGeneration.value",automaticCafGeneration.value);
      var data = {
        automaticCafGeneration: automaticCafGeneration.value ? 'yes' : 'no',
      };
      // $store.dispatch("updateAutoCaf", data).then(
      settingsStore.updateAutoCaf(data).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
        }
      );
    }        
    const pushToSubZones = () => {      
      allSubAccountsModalShow.value = true;
    }
    const subZoneRestrictions = () => { 
      subZoneModalShow.value = true;
    }
    const onclickclose = (val) => {
      allSubAccountsModalShow.value = val;
      subZoneModalShow.value = val;
    }

    return {
      settingsStore,
      subaccDetailsGetter,
      invoicePrefixGetter: invoicePrefixData,
      proformaInvoiceGetter: proformaInvoicePrefixData,
      paymentPrefixGetter: paymentPrefixData,
      usernamePrefixGetter: usernamePrefixData,
      circuitIdPrefixGetter: circuitIdPrefixData,
      leadPrefixGetter: leadPrefixData,
      helpdeskPrefixGetter: helpdeskPrefixData,
      creditNotePrefixGetter: creditNotePrefixData,
      cafPrefixGetter: cafPrefixData,
      invoiceDataUpdated,
      proformaInvoiceDataUpdated,
      paymentDataUpdated,
      circuitIdDataUpdated,
      leadDataUpdated,
      helpdeskDataUpdated,
      creditNoteDataUpdated,
      cafDataUpdated,
      getTheCurrentPrefix,
      invoiceData,
      proformaInvoiceData,
      paymentData,
      usernameData,
      circuitIdData,
      leadData,
      helpdeskData,
      creditNoteData,
      cafData,
      formClass,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      errorShow,
      selectedTab,
      selectedAccsInvoice,
      selectedAccsProformaInvoice,
      selectedAccsPayment,
      selectedAccsCreditNote,
      selectedAccsUsername,
      selectedAccsUsernameDis,
      selectedAccsCircuitId,
      selectedAccsLead,
      selectedAccsHelpdesk,
      selectedAccsCaf,
      selectedAccsBusinessInvoice,
      selectedAccsBusinessProformaInvoice,
      selectedAccsBusinessPayment,
      selectedAccsBusinessCreditNote,
      subAccOptions,
      selectedSubTab,
      selectedSubTabProforma,
      selectedSubTabPayment,
      selectedSubTabCircuit,
      selectedSubTabLead,
      selectedSubTabHelpdesk,
      selectedSubTabCreditNote,
      selectedSubTabCAF,
      addPrefixModal,
      deletePrefixModal,
      changeInvoiceNumModal,
      deleteInvoiceModal,
      prefixToDelete,
      prefixIdToDelete,
      prefDate,
      invDate,
      keyValue,
      deleteKeyValue,
      type,
      startNum,
      businessStartNum,
      prefixType,
      prefixInvoice,
      usrNamePrefix,
      automaticCafGeneration ,
      subZoneModalShow,
      allSubAccountsModalShow,
      prefixTab,
      prefixPageData,
      getPrefixData,
      switchTabs,
      addPrefix,
      deleteNumber,
      addNumber,
      deletePrefix,
      deleteNumberConfirm,
      createPrefixConfirm,
      deletePrefixConfirm,
      editInvoiceNum,
      checkPrefixNumberType,
      addEditInvoiceNum,
      savePrefixSettings,
      saveUserNamePrefix,
      updateAutoCaf,
      pushToSubZones,
      subZoneRestrictions,
      onclickclose,
      invoiceNumberBasedOnUserType,
      paymentNumberBasedOnUserType,
      proformaInvoiceNumberBasedOnUserType,
      creditNoteNumberBasedOnUserType,
      businessInvoiceDataUpdated,
      businessProformaInvoiceDataUpdated,
      businessPaymentDataUpdated,
      businessCreditNoteDataUpdated,
      startNumDiv,
      businessStartNumDiv
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster,
    VueCtkDateTimePicker,
    PrefixPushToSubZoneModal,
    PrefixSubZoneRestrictionsModal
  },
};
</script>
