<template>
    <div class="jazeMainPanel" style="height: 100%;">
      <div class="jazePanelContent jazePanelContent--scrollable">
        <div class="unifiSettingsContainer">
          <form ref="Tr069SettingsForm" id="Tr069SettingsForm" class="appForm appForm--cozy is-validation-hidden">
            <div class="unifiSettingsHeader">
              <div class="unifiSettingsHeader__title">TR-069 Settings</div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet toggleContent">
                <div class="unifiSettingsSection">
                <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('ssidComplexity')">
                  <span>SSID Complexity</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "ssidComplexity")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
                </div>
                <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "ssidComplexity") ? "" : "display:none"'>
                  <div class="appRow">
                    <div class="col--sm5 col--md4 col--lg3">
                      <label class="appLabel appLabel--primary"> SSID Password Complexity </label>
                    </div>
                    <div class="col--sm4 col--md5 col--lg5 appFormGroup appFormGroup--cozy">
                      <div class="appInputGroup verticalRhythmTop--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock">
                          <input type="radio" id="passwordComplexityNumbers" name="tr069wifiPasswordComplexity" value="n" v-model="tr069MandFieldsData.settingValues.tr069wifiPasswordComplexity">
                          <label for="passwordComplexityNumbers"> Numbers </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock">
                          <input type="radio" id="passwordComplexityAlphabets" name="tr069wifiPasswordComplexity" value="a" v-model="tr069MandFieldsData.settingValues.tr069wifiPasswordComplexity">
                          <label for="passwordComplexityAlphabets"> Alphabets </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock">
                          <input type="radio" id="passwordComplexityNA" name="tr069wifiPasswordComplexity" value="na" v-model="tr069MandFieldsData.settingValues.tr069wifiPasswordComplexity">
                          <label for="passwordComplexityNA"> Combination of numbers and alphabets </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock">
                          <input type="radio" id="passwordComplexityNAS" name="tr069wifiPasswordComplexity" value="nas" v-model="tr069MandFieldsData.settingValues.tr069wifiPasswordComplexity">
                          <label for="passwordComplexityNAS"> Combination of numbers, alphabets and special characters </label>
                        </div>
                      </div>
                    </div>

                    <div class="col--sm3 col--md3 col--lg4 appFormGroup appFormGroup--cozy">
                      <div class="appRow">
                        <div class="col--sm5">
                          <label class="appLabel appLabel--primary appLabel--boxAlign floatRight">Length</label>
                        </div>
                        <div class="col--sm7 appFormGroup appFormGroup--cozy">
                          <input class="input--full" type="text" name="tr069wifiPasswordLength" v-model="tr069wifiPasswordLength">
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </fieldset>
          </form>
          <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
            <div class="appFormFooter__right" v-if="tr069MandFieldsData != undefined && tr069MandFieldsData.visible == 'visible'">
                <!-- <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="tr069MandFieldsData.edition == 'isp'"> SubAccounts Restrictions </button>
                <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>
                <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="tr069MandFieldsData.edition == 'isp'"> Add to All SubAccounts </button>
                <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button> -->
                <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveTr069MandatoryFields"> Save </button>
            </div>
          </div>
        </div>
      </div>

      <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
        <div class="pos-relative" dialog-window>
          <div dialog-content>
            <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
              <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
                <div class="jazeModal__header pb-0">
                  <div class="jazeHeader jazeHeader--centered">
                    <div class="jazeHeader__title"> Save </div>
                    <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                      <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                        <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="jazeModal__main">
                  <div class="jazeModalContent pb-1">
                    <div class="container container--horizontal-cozy container--vertical-comfy">
                      <div class="row">
                        <div class="col jazeFormGroup jazeFormGroup--flex">
                          <label class="jazeLabel jazeLabel--primary" v-if="tr069MandFieldsData != undefined && tr069MandFieldsData.edition =='isp'">Allow/Deny sub-zones to change Tr069 fields settings</label>
                          <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change Tr069 fields settings</label>&nbsp;
                          <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                            <input type="checkbox" v-model="switchcheckboxTr069" id="switchcheckboxTr069" :checked="tr069MandFieldsData != undefined && tr069MandFieldsData.subAccountsCanNotChangeTr069FieldsValues == 'Yes' ? true : false">
                            <label for="switchcheckboxTr069"></label>
                          </div>
                        </div>
                      </div>
                      <div class="appType--micro appType--quiet italicized" v-if="tr069MandFieldsData != undefined && tr069MandFieldsData.edition =='isp'">
                        Note: If it is 'ON', sub-zones can not change Tr069 fields settings.
                      </div>
                      <div class="appType--micro appType--quiet italicized" v-else>
                        Note: If it is 'ON', sub-accounts can not change Tr069 fields settings.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="jazeModal__footer">
                  <div class="jazeButtonGroup jazeButtonGroup--cozy">
                    <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                    <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
      <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
      <PushToSubZone v-if="pushModal" :selected='selected' :edition="tr069MandFieldsData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'tr069fieldSetting'" :modalType="'subAccountsOnly'"></PushToSubZone>
    </div>
</template>


<script setup>
import { ref, computed, watchEffect, onMounted, onBeforeUnmount } from 'vue';
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import { usesettingsStore } from '@/store/settingsstore'
import { storeToRefs } from "pinia";
import { uselayoutStore } from '@/store/layoutstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore)
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  const settingsStore = usesettingsStore()
  const { tr069FieldsData } = storeToRefs(settingsStore)
  const tr069FieldSettingsGetter = computed(() => tr069FieldsData.value)
  const tr069wifiPasswordLength = ref("4")
  const viewToggleMenuData = ref('');
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const errorMessage = ref( "" )
  const tr069MandFieldsData = ref({ settingValues: {} });
  const subZoneModalShow = ref( false )
  const switchcheckboxTr069= ref( false )
  const pushModal = ref( false )
  const subAccOptions = ref( [
    {
      id: "selectAll",
      label: "Select All",
      children: [],
    },
  ] )
  const selected = ref( [] )


  onMounted(() => {
    gettr069FieldsData();
  });

  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  });

  watchEffect(() => {
    if (tr069FieldSettingsGetter.value && Object.keys(tr069FieldSettingsGetter.value).length > 0) {
      tr069MandFieldsData.value = { settingValues: {}, ...tr069FieldSettingsGetter.value };
    }
    if (
      tr069MandFieldsData.value.settingValues != undefined &&
      tr069MandFieldsData.value.settingValues.tr069wifiPasswordLength !=undefined
    ) {
      tr069wifiPasswordLength.value = tr069MandFieldsData.value.settingValues.tr069wifiPasswordLength;
    }
    if (
      tr069MandFieldsData.value.accountsArr != undefined &&
      tr069MandFieldsData.value.accountsArr.length != 0
    ) {
      for (let i = 0; i < tr069MandFieldsData.value.accountsArr.length; i++) {
        var deactivated = tr069MandFieldsData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
        subAccOptions.value[0].children.push({
          id: tr069MandFieldsData.value.accountsArr[i].id,
          label: tr069MandFieldsData.value.accountsArr[i].name + deactivated,
        });
      }
    }
    if (
        tr069MandFieldsData.value.subAccountsCanNotChangeTr069FieldsValues == "Yes"
      ) {
        switchcheckboxTr069.value = true;
      }
    console.log("Details:",tr069MandFieldsData)
  }); 

  const gettr069FieldsData = async () => {
    if (!tr069FieldSettingsGetter.value || Object.keys(tr069FieldSettingsGetter.value).length === 0) {
      await settingsStore.fetchtr069FieldSettings();
    } 
    tr069MandFieldsData.value = { settingValues: {}, ...tr069FieldSettingsGetter.value };
  };

  const viewToggleMenuDatafn = (value) => {
    viewToggleMenuData.value = viewToggleMenuData.value === value ? '' : value;
  };

  const saveTr069MandatoryFields = (e) => {
    successtoaster.value = false;
    errortoaster.value = false;
    e.preventDefault();
    var form = document.getElementById('Tr069SettingsForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    settingsStore.tr069SettingsSubmit(postData).then(  
      (response) => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }

  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxTr069.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeTr069FieldsValues",
      type: "tr069Fields",
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      (response) => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }

  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

  const onclickclose = () => {
    pushModal.value = false;
  }
</script>
